import { gql } from "@apollo/client"

export const dashboardUserTeamsList = gql`
  query DashboardUserTeamsList($input: DashboardUserTeamsListInput) {
    dashboardUserTeamsList(input: $input) {
      items {
        team {
          id
          isPrimary
          stakeholderType
          document {
            lang
            title
            logo
          }
        }
      }
      nextToken
    }
  }
`

export const dashboardTeamGroupList = gql`
  query DashboardTeamGroupList($input: DashboardTeamGroupListInput) {
    dashboardTeamGroupList(input: $input) {
      items {
        groupId
        name
      }
    }
  }
`

export const dashboardTeamCountriesList = gql`
  query DashboardTeamCountriesList($input: DashboardTeamCountriesListInput) {
    dashboardTeamCountriesList(input: $input) {
      items {
        country
      }
      nextToken
    }
  }
`

export const dashboardTotalUsersGet = gql`
  query DashboardTotalUsersGet($input: DashboardTotalUsersGetInput) {
    dashboardTotalUsersGet(input: $input) {
      users
      activeUsersPercentage
    }
  }
`

export const dashboardAverageUserGet = gql`
  query DashboardAverageUserGet($input: DashboardAverageUserGetInput) {
    dashboardAverageUserGet(input: $input) {
      country
      percentage
      users
    }
  }
`

export const dashboardMostActiveUserGet = gql`
  query DashboardMostActiveUserGet($input: DashboardMostActiveUserGetInput) {
    dashboardMostActiveUserGet(input: $input) {
      sub
      activityHour
      avgActionsComparisonPercentage
      avgEpisodesComparisonPercentage
      country
      sameActiveHourPercentage
      userActions
      userEpisodes
      user {
        first_name
        last_name
        country
        uid
        nickname
        profileImage
        sub
      }
    }
  }
`

export const dashboardChallengeGet = gql`
  query DashboardChallengeGet($input: DashboardChallengeGetInput) {
    dashboardChallengeGet(input: $input) {
      avgDailyMetrics
      challengeUsers
      challengeMetric
      daysTillTargetReached
      endsAt
      name
      reachedMetrics
      reachedPercentage
      startsAt
      targetAmount
      challenge {
        document {
          lang
          title
          image
        }
        metric {
          name
          unit
        }
      }
    }
  }
`

export const dashboardChallengeLeaderboardGet = gql`
  query DashboardChallengeLeaderboardGet(
    $input: DashboardChallengeLeaderboardGetInput
  ) {
    dashboardChallengeLeaderboardGet(input: $input) {
      items {
        previousWeekUserPosition
        sub
        userPoints
        userPointsDeltaPercentage
        userPosition
        userPositionDelta
        user {
          first_name
          last_name
          country
          uid
          nickname
          profileImage
          sub
        }
      }
      nextToken
    }
  }
`

export const dashboardActivityOverviewList = gql`
  query DashboardActivityOverviewList(
    $input: DashboardActivityOverviewListInput
  ) {
    dashboardActivityOverviewList(input: $input) {
      items {
        activityAmount
        activityDate
        activityType
        avgActivitiesDeltaPercentage
        avgActivityAmount
      }
      nextToken
    }
  }
`

export const dashboardActivitiesByCategoryList = gql`
  query DashboardActivitiesByCategoryList(
    $input: DashboardActivitiesByCategoryListInput
  ) {
    dashboardActivitiesByCategoryList(input: $input) {
      items {
        activityAmount
        activityCategory
        activityType
      }
      nextToken
    }
  }
`

export const dashboardSavingsGet = gql`
  query DashboardSavingsGet($input: DashboardSavingsGetInput) {
    dashboardSavingsGet(input: $input) {
      bathTubSaving
      co2Saving
      dishWasherSaving
      energySaving
      phoneChargeSaving
      waterSaving
    }
  }
`

export const dashboardFootprintOverviewGet = gql`
  query DashboardFootprintOverviewGet(
    $input: DashboardFootprintOverviewGetInput
  ) {
    dashboardFootprintOverviewGet(input: $input) {
      avgEmissionAmount
      footprintUsers
      maxEmissionAmount
      minEmissionAmount
      parisAgreementEmissionAmount
      parisAgreementEmissionDeltaPercentage
      totalUsers
      worldEmissionAmount
      worldEmissionDeltaPercentage
    }
  }
`

export const dashboardFootprintBreakdownGet = gql`
  query DashboardFootprintBreakdownGet(
    $input: DashboardFootprintBreakdownGetInput
  ) {
    dashboardFootprintBreakdownGet(input: $input) {
      items {
        category
        categoryEmissionAmount
        categoryEmissionPercentage
        totalEmissionAmount
        totalEmissionAsLaptopCharges
        totalEmissionAsLondonNYFlights
        totalEmissionAsTrees
      }
    }
  }
`

export const dashboardFootprintBreakdownByGroupGet = gql`
  query DashboardFootprintBreakdownByGroupGet(
    $input: DashboardFootprintBreakdownByGroupGetInput
  ) {
    dashboardFootprintBreakdownByGroupGet(input: $input) {
      items {
        category
        categoryEmissionAmount
        categoryEmissionPercentage
        groupId
      }
      nextToken
    }
  }
`

export const dashboardTopSdgsList = gql`
  query DashboardTopSdgsList($input: DashboardTopSdgsListInput) {
    dashboardTopSdgsList(input: $input) {
      items {
        activityAmount
        activitySdg
        position
        type
      }
      nextToken
    }
  }
`

export const dashboardTopCategoriesList = gql`
  query DashboardTopCategoriesList($input: DashboardTopCategoriesListInput) {
    dashboardTopCategoriesList(input: $input) {
      items {
        activityAmount
        activityCategory
        position
        type
      }
      nextToken
    }
  }
`

export const dashboardTopContentList = gql`
  query DashboardTopContentList($input: DashboardTopContentListInput) {
    dashboardTopContentList(input: $input) {
      items {
        activityAmount
        activityCategory
        activityName
        position
        type
        action {
          id
          image
          document {
            title
          }
          category {
            id
            code
            lang
            name
            backColor
            backTagColor
            foreColor
          }
        }
      }
      nextToken
    }
  }
`

export const dashboardCompletedEpisodesList = gql`
  query DashboardCompletedEpisodesList(
    $input: DashboardCompletedEpisodesListInput
  ) {
    dashboardCompletedEpisodesList(input: $input) {
      items {
        activityPartitionType
        partitionAmount
        partitionType
        totalAmount
      }
      nextToken
    }
  }
`

export const categoriesList = gql`
  query CategoriesList($input: ListCategoriesInput) {
    categoriesList(input: $input) {
      items {
        id
        code
        lang
        name
        backTagColor
        backColor
        foreColor
      }
    }
  }
`

export const sdgsList = gql`
  query SdgsList($input: ListSdgsInput) {
    sdgsList(input: $input) {
      items {
        id
        code
        lang
        name
        image
      }
    }
  }
`

export const dashboardTotalEducationHoursGet = gql`
  query DashboardTotalEducationHoursGet(
    $input: DashboardTotalEducationHoursGetInput
  ) {
    dashboardTotalEducationHoursGet(input: $input) {
      avgEducationHoursPerUser
      totalEducationHours
    }
  }
`

export const dashboardFootprintCategoryBreakdownGet = gql`
  query DashboardFootprintCategoryBreakdownGet(
    $input: DashboardFootprintCategoryBreakdownGetInput
  ) {
    dashboardFootprintCategoryBreakdownGet(input: $input) {
      items {
        category
        sector
        sectorEmissionAmount
        sectorEmissionPercentage
        categoryEmissionAmount
      }
    }
  }
`

export const dashboardTotalEducationHoursUsersGet = gql`
  query DashboardTotalEducationHoursUsersGet(
    $input: DashboardTotalEducationHoursUsersGetInput
  ) {
    dashboardTotalEducationHoursUsersGet(input: $input) {
      items {
        totalEducationHours
        user {
          first_name
          last_name
          country
          uid
          nickname
          profileImage
          sub
        }
      }
      nextToken
    }
  }
`

export const dashboardEpisodePerformanceList = gql`
  query DashboardEpisodePerformanceList(
    $input: DashboardEpisodePerformanceListInput
  ) {
    dashboardEpisodePerformanceList(input: $input) {
      items {
        position
        episodeCompleted
        episodeCorrectlyCompleted
        episode {
          id
          image
          translation {
            title
          }
          category {
            id
            code
            lang
            name
            backColor
            backTagColor
            foreColor
          }
        }
      }
      nextToken
    }
  }
`

export const dashboardDailyChallengeOverviewGet = gql`
  query DashboardDailyChallengeOverviewGet(
    $input: DashboardDailyChallengeOverviewGetInput
  ) {
    dashboardDailyChallengeOverviewGet(input: $input) {
      items {
        activityDate
        activityAmount
      }
      nextToken
    }
  }
`

export const challengesPastList = gql`
  query ChallengesPastList($input: ChallengesPastListInput) {
    challengesPastList(input: $input) {
      id
      name
      userCount
      currentAmount
      targetAmount
      startsAt
      endsAt
      document {
        lang
        title
        image
      }
      metric {
        name
        unit
      }
    }
  }
`

export const dashboardFootprintMonthlyUserGet = gql`
  query DashboardFootprintMonthlyUserGet(
    $input: DashboardFootprintMonthlyUserGetInput
  ) {
    dashboardFootprintMonthlyUserGet(input: $input) {
      items {
        activityAmount
        activityDate
      }
    }
  }
`

export const dashboardActivityList = gql`
  query DashboardActivityListV2($input: DashboardActivityListInputV2) {
    dashboardActivityListV2(input: $input) {
      items {
        activityDate
        activityAmount
      }
    }
  }
`
