import {
  LinearProgress,
  Table as MUITable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { CSSProperties, ReactNode } from "react"
import { defaultBoxShadow } from "../../services/config/constants"
import Title from "./Title"
import "../../styles/table.css"

const Table = ({
  headingItems,
  children,
  style,
  loading,
  loadingMore,
}: {
  headingItems: (string | ReactNode)[]
  children: ReactNode
  style?: CSSProperties
  loading?: boolean
  loadingMore?: boolean
}) => {
  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: 16,
        boxShadow: defaultBoxShadow,
        ...style,
        position: "relative",
      }}
    >
      {loading ? (
        <LinearProgress
          style={{ width: "100%", position: "absolute", top: 47 }}
        />
      ) : null}
      <MUITable sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow sx={{ "td, th": { border: 0 } }}>
            {headingItems.map((item, index) => (
              <TableCell key={index}>
                <Title fontSize={16}>{item}</Title>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </MUITable>
      {loadingMore && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        />
      )}
    </TableContainer>
  )
}

export default Table
