import { Skeleton, Stack } from "@mui/material"
import Title from "../global/Title"

const ChallengeLeaderboardListItemSkeleton = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: 66,
        paddingLeft: 18,
        position: "relative",
      }}
    >
      <Skeleton
        variant="circular"
        style={{ width: 25, height: 24, borderRadius: 4 }}
      />
      <Skeleton
        variant="circular"
        style={{ width: 32, minWidth: 32, height: 32, marginLeft: 17 }}
      />
      <Title
        fontSize={14}
        style={{ maxWidth: "calc(100% - 170px)", marginLeft: 15 }}
        loading
      />
      <Title
        fontSize={14}
        style={{ position: "absolute", right: 18 }}
        loading
        skeletonWidth={35}
      />
    </Stack>
  )
}

export default ChallengeLeaderboardListItemSkeleton
