import { CSSProperties, useEffect, useState } from "react"
import { colors } from "../../services/config/colors"
import Title from "../global/Title"
import { Skeleton } from "@mui/material"
import { formatNumber } from "../../services/utils/utils"

const ChallengeProgressBar = ({
  percentage,
  loading,
  showPercentageStartingFrom = 15,
  style,
}: {
  percentage: number
  loading: boolean
  showPercentageStartingFrom?: number
  style?: CSSProperties
}) => {
  // bar percentage, different from the props one beacause the max of this one is 100
  const [barPercentage, setBarPercentage] = useState<number>(0)

  useEffect(() => {
    setBarPercentage(percentage > 100 ? 100 : percentage)
  }, [percentage])

  return loading ? (
    <Skeleton
      variant="rectangular"
      style={{
        width: "100%",
        height: 15,
        borderRadius: 100,
        ...style,
      }}
    />
  ) : (
    <div
      style={{
        width: "100%",
        height: 15,
        overflow: "hidden",
        position: "relative",
        borderRadius: 100,
        backgroundColor: colors.background,
        display: "flex",
        alignItems: "center",
        border: "1px solid " + colors.primary,
        ...style,
      }}
    >
      <div
        style={{
          width: `${barPercentage}%`,
          height: "100%",
          backgroundColor: colors.primary,
          borderRadius: 100,
        }}
      />
      {percentage >= showPercentageStartingFrom ? (
        <Title
          fontSize={12}
          color={colors.textWhite}
          style={{
            position: "absolute",
            right: `calc(${100 - barPercentage}% + 5px)`,
          }}
        >
          {formatNumber(percentage)}%
        </Title>
      ) : null}
    </div>
  )
}

export default ChallengeProgressBar
