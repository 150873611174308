import {
  ButtonBase,
  Popover,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Button from "./Button"
import calendarWhiteIcon from "../../assets/icons/calendar-white.svg"
import calendarPrimaryIcon from "../../assets/icons/calendar-primary.svg"
import { useContext, useState } from "react"
import "../../styles/dateRangePicker.css"
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { MainContext } from "../../controllers/main"
import months from "../../services/config/months"
import i18next, { t } from "i18next"
import Text from "./Text"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en"
import "dayjs/locale/it"
import { defaultTransition } from "../../services/config/constants"
import { AuthContext } from "../../controllers/auth"
import { HelperContext } from "../../controllers/helper"

const DateRangePicker = ({
  primary,
  disabled,
}: {
  primary?: boolean
  disabled?: boolean
}) => {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"))
  const { isAdmin, isSuperAdmin } = useContext(AuthContext)
  const { dateRange, setDateRange } = useContext(MainContext)
  const { loading, getData } = useContext(HelperContext)

  // popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseAndReset = () => {
    setAnchorEl(null)
    setTimeout(() => {
      resetLocalDateRange()
    }, 100)
  }

  const open = Boolean(anchorEl)

  // local date range (to not affect the global real one)
  const [localDateRange, setLocalDateRange] = useState<{
    start: Date | null
    end: Date | null
  }>({
    start: dateRange.start,
    end: dateRange.end,
  })

  // reset local date range (make it equal to dateRange)
  const resetLocalDateRange = () => {
    setLocalDateRange({
      start: dateRange.start,
      end: dateRange.end,
    })
  }

  // check if there are difference between dateRange and localDateRange
  const areRangesDifferent = () => {
    if (
      (!localDateRange.start &&
        !dateRange.start &&
        !localDateRange.end &&
        !dateRange.end) ||
      (localDateRange.start &&
        dateRange.start &&
        localDateRange.end &&
        dateRange.end &&
        localDateRange.start.toDateString() ===
          dateRange.start.toDateString() &&
        localDateRange.end.toDateString() === dateRange.end.toDateString())
    ) {
      return true
    }

    return false
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18next.language}
    >
      <Button
        primary={primary}
        style={{
          width: "auto",
          minWidth: matchesSM ? 160 : 40,
          paddingInline: matchesSM ? 16 : 12,
        }}
        disabled={disabled || loading}
        onClick={handleClick}
      >
        <Stack direction="row" alignItems="center" style={{ gap: 10 }}>
          <img
            src={primary ? calendarWhiteIcon : calendarPrimaryIcon}
            style={{ width: 16, height: 16 }}
            alt=""
          />
          {!matchesSM ? null : !disabled &&
            dateRange.start &&
            dateRange.end &&
            dateRange.start.getFullYear() === dateRange.end.getFullYear() &&
            dateRange.start.getFullYear() === new Date().getFullYear() ? (
            <div>
              {dateRange.start.getDate()}{" "}
              {t(months[dateRange.start.getMonth()]).slice(0, 3)} -{" "}
              {dateRange.end.getDate()}{" "}
              {t(months[dateRange.end.getMonth()]).slice(0, 3)}
            </div>
          ) : !disabled && dateRange.start && dateRange.end ? (
            <div>
              {dateRange.start.getDate()}{" "}
              {t(months[dateRange.start.getMonth()]).slice(0, 3)}{" "}
              {dateRange.start.getFullYear()} - {dateRange.end.getDate()}{" "}
              {t(months[dateRange.end.getMonth()]).slice(0, 3)}{" "}
              {dateRange.end.getFullYear()}
            </div>
          ) : (
            <div>{t("all_time")}</div>
          )}
        </Stack>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseAndReset}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: "popover-paper-date-range-picker" }}
      >
        <Stack>
          <Stack direction="row">
            {matchesSM && (
              <Stack
                gap={2}
                style={{
                  width: 180,
                  minWidth: 180,
                  paddingLeft: 22,
                  paddingTop: 21,
                }}
              >
                <ButtonBase
                  disableRipple
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    setLocalDateRange({
                      start: new Date(
                        dayjs(new Date()).subtract(6, "days").toString()
                      ),
                      end: new Date(),
                    })
                  }}
                >
                  <Text fontWeight={500} ellipsis>
                    {t("past_count_days", { count: 7 })}
                  </Text>
                </ButtonBase>
                <ButtonBase
                  disableRipple
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    setLocalDateRange({
                      start: new Date(
                        dayjs(new Date()).subtract(29, "days").toString()
                      ),
                      end: new Date(),
                    })
                  }}
                >
                  <Text fontWeight={500} ellipsis>
                    {t("past_count_days", { count: 30 })}
                  </Text>
                </ButtonBase>
                {isAdmin || isSuperAdmin ? (
                  <ButtonBase
                    disableRipple
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => {
                      setLocalDateRange({
                        start: new Date(
                          dayjs(new Date()).subtract(6, "months").toString()
                        ),
                        end: new Date(),
                      })
                    }}
                  >
                    <Text fontWeight={500} ellipsis>
                      {t("past_count_months", { count: 6 })}
                    </Text>
                  </ButtonBase>
                ) : null}
                <ButtonBase
                  disableRipple
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    if (isSuperAdmin) {
                      setLocalDateRange({
                        start: null,
                        end: null,
                      })
                    } else {
                      setLocalDateRange({
                        start: isAdmin
                          ? new Date("2023-04-20")
                          : new Date("2024-09-01"),
                        end: new Date(),
                      })
                    }
                  }}
                >
                  <Text fontWeight={500} ellipsis>
                    {t("all_time")}
                  </Text>
                </ButtonBase>
              </Stack>
            )}
            <Stack
              style={{ width: "100%" }}
              direction={matchesSM ? "row" : "column"}
            >
              <DateCalendar
                value={
                  localDateRange.start
                    ? dayjs(localDateRange.start.toISOString())
                    : null
                }
                onChange={(newValue) => {
                  localDateRange.start = new Date(newValue.toString())
                  setLocalDateRange({ ...localDateRange })
                }}
                disableFuture
                maxDate={
                  localDateRange.end
                    ? dayjs(localDateRange.end.toISOString()).subtract(1, "day")
                    : null
                }
                minDate={
                  isSuperAdmin
                    ? null
                    : isAdmin
                    ? dayjs(new Date("2023-04-20"))
                    : dayjs(new Date("2024-09-01"))
                }
              />
              <DateCalendar
                value={
                  localDateRange.end
                    ? dayjs(localDateRange.end.toISOString())
                    : null
                }
                onChange={(newValue) => {
                  localDateRange.end = new Date(newValue.toString())
                  setLocalDateRange({ ...localDateRange })
                }}
                disableFuture
                minDate={
                  localDateRange.start
                    ? dayjs(localDateRange.start.toISOString()).add(1, "day")
                    : null
                }
              />
            </Stack>
          </Stack>
          <Stack
            style={{
              height: localDateRange.start || localDateRange.end ? 16 : 0,
              overflow: "hidden",
              transition: defaultTransition,
            }}
          >
            {localDateRange.start || localDateRange.end ? (
              <Text fontSize={12} textAlign="right" paddingRight={22}>
                {`${t("from")} ${
                  localDateRange.start
                    ? localDateRange.start.toLocaleDateString()
                    : "--"
                } ${t("to")} ${
                  localDateRange.end
                    ? localDateRange.end.toLocaleDateString()
                    : "--"
                }`}
              </Text>
            ) : null}
          </Stack>
          <Stack
            direction="row"
            gap={2}
            justifyContent="flex-end"
            style={{
              width: "100%",
              paddingBottom: 22,
              paddingInline: 22,
              marginTop: localDateRange.start || localDateRange.end ? 16 : 4,
              transition: defaultTransition,
            }}
          >
            <Button
              disabled={areRangesDifferent()}
              style={{ minWidth: 150 }}
              onClick={resetLocalDateRange}
            >
              {t("cancel")}
            </Button>
            <Button
              primary
              disabled={
                (!localDateRange.start && localDateRange.end) ||
                (!localDateRange.end && localDateRange.start) ||
                areRangesDifferent()
                  ? true
                  : false
              }
              style={{ minWidth: 150 }}
              onClick={() => {
                if (!localDateRange.start && !localDateRange.end) {
                  dateRange.start = null
                  dateRange.end = null
                } else {
                  dateRange.start = new Date(
                    localDateRange.start!.getFullYear(),
                    localDateRange.start!.getMonth(),
                    localDateRange.start!.getDate(),
                    new Date().getHours()
                  )
                  dateRange.end = new Date(
                    localDateRange.end!.getFullYear(),
                    localDateRange.end!.getMonth(),
                    localDateRange.end!.getDate(),
                    new Date().getHours()
                  )
                }

                setDateRange({ ...dateRange })

                getData()
                handleClose()
              }}
            >
              {t("apply_dates")}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </LocalizationProvider>
  )
}

export default DateRangePicker
