export enum FilterType {
  location = "location",
  group = "group",
  gender = "gender",
  age = "age",
}

export enum TeamStakeholderType {
  customers = "customers",
  citizens = "citizens",
  employees = "employees",
  students = "students",
  fans = "fans",
  general = "general",
}

export enum DashboardActivityType {
  episode = "episode",
  action = "action",
  quiz = "quiz",
  mobility = "mobility",
  footprint = "footprint",
  news = "news",
  survey = "survey",
  wellness = "wellness",
}
