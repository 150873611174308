import { Stack } from "@mui/material"
import Card from "../global/Card"
import Title from "../global/Title"
import Text from "../global/Text"
import InfoCard from "../global/InfoCard"
import { colors } from "../../services/config/colors"
import ChallengeProgressBar from "./ChallengeProgressBar"
import { useTranslation } from "react-i18next"
import peopleLightIcon from "../../assets/icons/people-light.svg"
import calendarLightIcon from "../../assets/icons/calendar-light.svg"
import trophyIcon from "../../assets/icons/trophy.svg"
import PastChallenge from "../../models/pastChallenge"
import {
  formatNumber,
  roundWithDecimalPlaces,
} from "../../services/utils/utils"
import months from "../../services/config/months"
import Button from "../global/Button"
import { useNavigate } from "react-router-dom"

const PastChallengeCard = ({
  challenge,
  loading,
}: {
  challenge: PastChallenge
  loading: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Card width="100%" gap={2} style={{ height: "100%" }}>
      <Stack direction="row" style={{ gap: 20 }}>
        <Stack style={{ width: "100%" }}>
          <Title
            fontSize={22}
            lineHeight="28px"
            loading={loading}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {challenge.document.title}
          </Title>
          <Stack
            direction="row"
            alignItems="center"
            style={{ height: 24, marginTop: 12, gap: 7 }}
          >
            <img src={peopleLightIcon} style={{ width: 16 }} />
            <Text color={colors.textSecondary} loading={loading}>
              {formatNumber(challenge.userCount)} {t("participants")}
            </Text>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            style={{ height: 24, marginTop: 6, gap: 7 }}
          >
            <img src={calendarLightIcon} style={{ width: 16 }} />
            <Text color={colors.textSecondary} loading={loading}>
              {`${new Date(challenge.startsAt).getDate()} ${t(
                months[new Date(challenge.startsAt).getMonth()]
              ).slice(0, 3)} - ${new Date(challenge.endsAt).getDate()} ${t(
                months[new Date(challenge.endsAt).getMonth()]
              ).slice(0, 3)}`}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <div style={{ flex: 1 }}>
        <InfoCard loading={loading}>
          <Stack
            direction="row"
            alignItems="center"
            style={{ height: 25, gap: 9 }}
          >
            <img src={trophyIcon} style={{ width: 18 }} />
            <Title fontSize={18} lineHeight="20px" loading={loading}>
              {t("goal")}: {formatNumber(challenge.targetAmount)}{" "}
              {challenge.metric.name.toLowerCase()}
            </Title>
          </Stack>
          <ChallengeProgressBar
            percentage={roundWithDecimalPlaces(
              (challenge.currentAmount / challenge.targetAmount) * 100,
              1
            )}
            loading={loading}
            style={{ marginTop: 10 }}
          />
        </InfoCard>
      </div>
      <Button
        primary
        onClick={() => {
          navigate(window.location.pathname + `/${challenge.id}`)
        }}
        loading={loading}
      >
        {t("view_leaderboard")}
      </Button>
    </Card>
  )
}

export default PastChallengeCard
