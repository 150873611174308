import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { colors } from "../../services/config/colors"
import "../../styles/lineChart.css"
import ChartDataItem from "../../models/chartDataItem"
import { useEffect, useState } from "react"
import { Skeleton } from "@mui/material"
import { t } from "i18next"
import {
  formatNumber,
  roundWithDecimalPlaces,
} from "../../services/utils/utils"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
)

const LineChartSelectable = ({
  data,
  selectedDataset,
  loading,
  lineWidth = 2,
}: {
  data: { label: string; data: ChartDataItem[] }[]
  selectedDataset: number
  loading: boolean
  lineWidth?: number
}) => {
  // data for chart
  const [chartData, setChartData] = useState<any | null>(null)

  useEffect(() => {
    if (!loading && data[0].data[0]) {
      setChartData({
        labels: data[0].data.map((item) => item.label),
        datasets: data.map((item) => {
          return {
            label: item.label,
            data: item.data.map((item2) => item2.value),
            backgroundColor: item.data[0].backgroundColor,
            borderColor: item.data[0].backgroundColor,
          }
        }),
      })
    }
  }, [loading, data])

  return loading || !chartData ? (
    <Skeleton variant="rounded" style={{ width: "100%", height: "100%" }} />
  ) : (
    <Line
      style={{ maxWidth: "100%" }}
      options={{
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            border: {
              color: colors.chartsGrid,
            },
            grid: {
              display: false,
            },
            ticks: {
              color: colors.textSecondary,
              font: {
                family: "Satoshi",
                size: 14,
              },
              callback: function (value) {
                const label: string = this.getLabelForValue(value as number)

                if (label.split(" ").length === 3) {
                  return label.slice(0, label.indexOf(" ") + 4)
                }

                return label.slice(0, 3)
              },
              maxRotation: 0,
              maxTicksLimit: 12,
            },
          },
          y: {
            min: 0,
            border: {
              width: 0,
            },
            grid: {
              color: colors.chartsGrid,
              tickWidth: 0,
            },
            ticks: {
              color: colors.textSecondary,
              font: {
                family: "Satoshi",
                size: 14,
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            borderWidth: (context) => {
              return context.datasetIndex === selectedDataset
                ? lineWidth + 1
                : lineWidth
            },
            cubicInterpolationMode: "monotone",
          },
          point: {
            radius: (context) => {
              return context.datasetIndex === selectedDataset
                ? lineWidth + 0.4
                : 0
            },
            hoverRadius: (context) => {
              return context.datasetIndex === selectedDataset ? 4.5 : 0
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: false,
            filter: (tooltipItem) => {
              return tooltipItem.datasetIndex === selectedDataset
            },
            external: (context) => {
              if (!context.tooltip.dataPoints[0]) {
                let tooltipEl = document.getElementById("line-chart-tooltip")
                if (tooltipEl) {
                  tooltipEl.remove()
                }
                return
              }

              // tooltip element and model
              let tooltipEl = document.getElementById("line-chart-tooltip")
              const tooltipModel = context.tooltip

              // create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div")
                tooltipEl.id = "line-chart-tooltip"
                tooltipEl.innerHTML = `<div class="line-chart-tooltip-container">
                    <div class="line-chart-tooltip-content" id="line-chart-tooltip-content"></div>
                    <div class="line-chart-tooltip-arrow" />
                  </div>`
                document.body.appendChild(tooltipEl)
              }

              // hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = "0"
                return
              }

              // delta percentage
              const deltaPercentage = roundWithDecimalPlaces(
                data.find(
                  (item: any) =>
                    item.label === context.tooltip.dataPoints[0].dataset.label
                )!.data[context.tooltip.dataPoints[0].dataIndex]
                  .avgActivitiesDeltaPercentage! * 100,
                0
              )

              // set tooltip content
              const currentTooltip = document.getElementById(
                "line-chart-tooltip-content"
              )!
              currentTooltip.innerHTML =
                deltaPercentage === 0 || Number.isNaN(deltaPercentage)
                  ? `
              <p class="line-chart-tooltip-title">${tooltipModel.title[0]}</p>
              <p class="line-chart-tooltip-text-ellipsis" style="color: ${
                context.tooltip.dataPoints[0].dataset.backgroundColor
              };">${formatNumber(
                      context.tooltip.dataPoints[0].raw as number
                    )} ${context.tooltip.dataPoints[0].dataset.label?.toLowerCase()}</p>
            `
                  : `
                <p class="line-chart-tooltip-title">${tooltipModel.title[0]}</p>
                <p class="line-chart-tooltip-text-ellipsis" style="color: ${
                  context.tooltip.dataPoints[0].dataset.backgroundColor
                };">${formatNumber(
                      context.tooltip.dataPoints[0].raw as number
                    )} ${context.tooltip.dataPoints[0].dataset.label?.toLowerCase()}</p>
                <p class="line-chart-tooltip-bottom-line" style="color: ${
                  deltaPercentage >= 0 ? colors.success : colors.error
                }">${deltaPercentage >= 0 ? "+" : ""}${formatNumber(
                      deltaPercentage
                    )}% ${
                      deltaPercentage >= 0
                        ? t("above_average")
                        : t("below_average")
                    }</p>
              `

              // get chart width and tooltip offset
              const tooltipOffset =
                (context.chart.scales.x.width /
                  (context.tooltip.dataPoints[0].dataset.data.length - 1)) *
                context.tooltip.dataPoints[0].dataIndex

              // display and position
              const position = context.chart.canvas.getBoundingClientRect()
              tooltipEl.style.opacity = "1"
              tooltipEl.style.position = "absolute"
              tooltipEl.style.left =
                position.left -
                65 +
                window.scrollX +
                tooltipOffset +
                context.chart.scales.x.left +
                "px"
              tooltipEl.style.top =
                position.top + window.scrollY + tooltipModel.caretY - 84 + "px"
              tooltipEl.style.pointerEvents = "none"
            },
          },
        },
      }}
      data={chartData}
    />
  )
}

export default LineChartSelectable
