import { useTranslation } from "react-i18next"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import PageContainer from "../../../components/global/PageContainer"
import PastChallengeCard from "../../../components/challenge/PastChallengeCard"
import { useContext } from "react"
import { Grid } from "@mui/material"
import { EngagementContext } from "../../../controllers/engagement"

const PastChallenges = () => {
  const { t } = useTranslation()
  const { engagementDataLoading, pastChallengesList } =
    useContext(EngagementContext)

  return (
    <PageContainer
      title={t("past_challenges")}
      breadcrumbs={[
        { title: t("engagement"), path: "/engagement" },
        { title: t("past_challenges") },
      ]}
      withDatePicker={false}
      withFilters={false}
    >
      <Grid container spacing={3} columns={2}>
        {pastChallengesList.map((pastChallenge) => (
          <Grid key={pastChallenge.id} item sm={2} md={1}>
            <FadeFromTop style={{ height: "100%" }}>
              <PastChallengeCard
                challenge={pastChallenge}
                loading={engagementDataLoading}
              />
            </FadeFromTop>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  )
}

export default PastChallenges
