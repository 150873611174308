import { useContext, useEffect } from "react"
import PageContainer from "../../../components/global/PageContainer"
import i18next, { t } from "i18next"
import { ActivitiesContext } from "../../../controllers/activities"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import Card from "../../../components/global/Card"
import { Stack } from "@mui/material"
import Title from "../../../components/global/Title"
import Text from "../../../components/global/Text"
import { colors } from "../../../services/config/colors"
import Select from "../../../components/global/Select"
import LineChart from "../../../components/global/LineChart"

const Activities = () => {
  const {
    activitiesDataLoading,
    activitiesList,
    getActivitiesData,
    activitiesListOptions,
    activitiesListSelectedOption,
    setActivitiesListSelectedOption,
  } = useContext(ActivitiesContext)

  // get data
  useEffect(() => {
    getActivitiesData()
  }, [activitiesListSelectedOption])

  return (
    <PageContainer
      title={t("activities_and_missions")}
      breadcrumbs={[{ title: t("activities_and_missions") }]}
    >
      <FadeFromTop>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("overview_overtime")}</Title>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 69,
                position: "relative",
              }}
            >
              <Stack style={{ position: "absolute", right: 0, gap: 6 }}>
                <Text
                  fontSize={14}
                  lineHeight="14px"
                  fontWeight={500}
                  color={colors.primary}
                >
                  {t("show")}
                </Text>
                <Select
                  options={activitiesListOptions.map((option) => option.label)}
                  selectedOption={activitiesListSelectedOption}
                  onChange={(newValue) =>
                    setActivitiesListSelectedOption(newValue)
                  }
                  popoverWidth={160}
                  style={{ width: 160 }}
                  loading={activitiesDataLoading}
                />
              </Stack>
            </Stack>
            <Stack>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("activities"),
                      data: activitiesList.map((item) => {
                        return {
                          label: new Date(item.activityDate).toLocaleDateString(
                            i18next.language,
                            {
                              day: "numeric",
                              year: "numeric",
                              month: "long",
                            }
                          ),
                          value: item.activityAmount,
                          backgroundColor: colors.categories.episodes,
                        }
                      }),
                    },
                  ]}
                  loading={activitiesDataLoading}
                />
              </div>
            </Stack>
          </Stack>
        </Card>
      </FadeFromTop>
    </PageContainer>
  )
}

export default Activities
