import {
  ButtonBase,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import PageContainer from "../../../components/global/PageContainer"
import Title from "../../../components/global/Title"
import Button from "../../../components/global/Button"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import { useContext, useEffect, useState } from "react"
import { colors } from "../../../services/config/colors"
import Card from "../../../components/global/Card"
import InfoCard from "../../../components/global/InfoCard"
import analyticsIcon from "../../../assets/icons/analytics.svg"
import Text from "../../../components/global/Text"
import infoPrimaryIcon from "../../../assets/icons/info-primary.svg"
import Tabs from "../../../components/global/Tabs"
import peopleLightIcon from "../../../assets/icons/people-light.svg"
import calendarLightIcon from "../../../assets/icons/calendar-light.svg"
import trophyIcon from "../../../assets/icons/trophy.svg"
import ChallengeProgressBar from "../../../components/challenge/ChallengeProgressBar"
import Select from "../../../components/global/Select"
import BarChart from "../../../components/global/BarChart"
import ChallengeLeaderboardTop3 from "../../../components/challenge/ChallengeLeaderboardTop3"
import ChallengeLeaderboardListItem from "../../../components/challenge/ChallengeLeaderboardListItem"
import { AnimatePresence, motion } from "framer-motion"
import playWhiteIcon from "../../../assets/icons/play-white.svg"
import actionWhiteIcon from "../../../assets/icons/action-white.svg"
import co2WhiteIcon from "../../../assets/icons/co2-white.svg"
import waterWhiteIcon from "../../../assets/icons/water-white.svg"
import energyWhiteIcon from "../../../assets/icons/energy-white.svg"
import dishwasherWhiteIcon from "../../../assets/icons/dishwasher-white.svg"
import bathtubeWhiteIcon from "../../../assets/icons/bathtube-white.svg"
import batteryWhiteIcon from "../../../assets/icons/battery-white.svg"
import TeamSavingsCard from "../../../components/savings/TeamSavingsCard"
import InfoStack from "../../../components/global/InfoStack"
import { useNavigate } from "react-router-dom"
import { Trans } from "react-i18next"
import { t } from "i18next"
import { MainContext } from "../../../controllers/main"
import InfoCardSelectable from "../../../components/global/InfoCardSelectable"
import months from "../../../services/config/months"
import OverviewLineChart from "../../../components/engagement/OverviewLineChart"
import OverviewIntervalSelect from "../../../components/engagement/OverviewIntervalSelect"
import OverviewCardDataItem from "../../../models/overviewCardDataItem"
import {
  capitalizeFirstCharacter,
  convertNumberToHoursMinutes,
  formatNumber,
} from "../../../services/utils/utils"
import ChallengeLeaderboardListItemSkeleton from "../../../components/challenge/ChallengeLeaderboardListItemSkeleton"
import AlertInfo from "../../../components/global/AlertInfo"
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material"
import { AuthContext } from "../../../controllers/auth"
import ChallengeOverviewDataItem from "../../../models/challengeOverviewDataItem"
import ChallengeOverviewLineChart from "../../../components/engagement/ChallengeOverviewLineChart"
import ChallengeOverviewIntervalSelect from "../../../components/engagement/ChallengeOverviewIntervalSelect"
import { EngagementContext } from "../../../controllers/engagement"

const Engagement = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"))
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const { team } = useContext(AuthContext)
  const { categories } = useContext(MainContext)
  const {
    engagementDataLoading,
    getEngagementData,
    dashboardCardData,
    challenge,
    pastChallengesList,
    leaderboard,
    byCategoriesCardData,
    teamSavingsCardData,
  } = useContext(EngagementContext)

  // get data
  useEffect(() => {
    getEngagementData()
  }, [])

  // challenge in progress selected tab
  const challengeTabs = [t("overview"), t("leaderboard")]
  const [challengeSelectedTab, setChallengeSelectedTab] = useState<number>(0)

  // challenge overview logic
  const challengeOverviewOptions = [
    t("weekly"),
    t("monthly"),
    t("annual"),
    t("total"),
  ]
  const [challengeOverviewSelectedOption, setChallengeOverviewSelectedOption] =
    useState<number>(1)
  const [challengeOverviewInterval, setChallengeOverviewInterval] = useState<{
    start: Date
    end: Date
  }>({
    start: new Date(new Date().getFullYear(), 0, 1, new Date().getHours()),
    end: new Date(new Date().getFullYear(), 11, 31, new Date().getHours()),
  })
  const [challengeOverviewSelectedItem, setChallengeOverviewSelectedItem] =
    useState<number>(0)
  const [dataForChallengeOverviewChart, setDataForChallengeOverviewChart] =
    useState<ChallengeOverviewDataItem[]>([])

  // content overview logic
  const contentOptions = [t("weekly"), t("monthly"), t("annual"), t("total")]
  const [contentSelectedOption, setContentSelectedOption] = useState<number>(1)
  const [contentInterval, setContentInterval] = useState<{
    start: Date
    end: Date
  }>({
    start: new Date(new Date().getFullYear(), 0, 1, new Date().getHours()),
    end: new Date(new Date().getFullYear(), 11, 31, new Date().getHours()),
  })
  const [contentSelectedItem, setContentSelectedItem] = useState<number>(0)
  const [dataForChart, setDataForChart] = useState<OverviewCardDataItem[]>([])
  const [dataVisibility, setDataVisibility] = useState<boolean[]>([true, true])

  // by categories tabs
  const categoriesTabs = [t("episodes"), t("actions")]
  const [categoriesSelectedTab, setCategoriesSelectedTab] = useState<number>(0)

  // info alerts
  const [challengeAlertOpen, setChallengeAlertOpen] = useState<boolean>(false)
  const [overviewAlertOpen, setOverviewAlertOpen] = useState<boolean>(false)
  const [savingsAlertOpen, setSavingsAlertOpen] = useState<boolean>(false)

  return (
    <PageContainer
      title={t("engagement")}
      breadcrumbs={[{ title: t("engagement") }]}
    >
      <FadeFromTop>
        {/* dashboard */}
        <Card>
          <Title>{t("dashboard")}</Title>
          <Stack direction={{ xs: "column", md: "row" }} gap={3}>
            <Stack
              direction="column"
              gap={3}
              style={{ width: matchesMD ? "100%" : "50%" }}
            >
              <InfoCard
                onClick={() => {
                  navigate("/engagement/users")
                }}
                loading={engagementDataLoading}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  style={{ width: "100%", height: 54 }}
                >
                  <div
                    className="center"
                    style={{
                      width: 50,
                      height: 50,
                      backgroundColor: colors.primary,
                      borderRadius: 7,
                    }}
                  >
                    <img
                      src={analyticsIcon}
                      style={{ width: 25, height: 25 }}
                      alt=""
                    />
                  </div>
                  <Stack>
                    <Text
                      fontWeight={500}
                      lineHeight="24px"
                      color={colors.textSecondary}
                    >
                      {t("total_users")}
                    </Text>
                    <Title
                      fontSize={22}
                      lineHeight="30px"
                      loading={engagementDataLoading}
                    >
                      {dashboardCardData
                        ? formatNumber(dashboardCardData.totalUsers)
                        : t("no_data")}
                    </Title>
                  </Stack>
                </Stack>
                <Text
                  fontSize={14}
                  fontWeight={500}
                  color={colors.success}
                  style={{ marginTop: matchesMD ? 10 : 28 }}
                  loading={engagementDataLoading}
                >
                  {dashboardCardData?.activeUsersPercentage
                    ? t("active_users", {
                        n: formatNumber(
                          dashboardCardData?.activeUsersPercentage
                        ),
                      })
                    : t("no_data")}
                </Text>
              </InfoCard>
              <InfoCard
                clickable={team?.isPrimary}
                onClick={() => {
                  navigate("/engagement/education-hours")
                }}
                loading={engagementDataLoading}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  style={{ width: "100%", height: 54 }}
                >
                  <div
                    className="center"
                    style={{
                      width: 50,
                      height: 50,
                      backgroundColor: colors.primary,
                      borderRadius: 7,
                    }}
                  >
                    <img
                      src={analyticsIcon}
                      style={{ width: 25, height: 25 }}
                      alt=""
                    />
                  </div>
                  <Stack>
                    <Text
                      fontWeight={500}
                      lineHeight="24px"
                      color={colors.textSecondary}
                    >
                      {t("total_education_hours")}
                    </Text>
                    <Title
                      fontSize={22}
                      lineHeight="30px"
                      loading={engagementDataLoading}
                    >
                      {dashboardCardData?.totalEducationHours
                        ? `${formatNumber(
                            dashboardCardData?.totalEducationHours
                          )} ${t("hours")}`
                        : t("no_data")}
                    </Title>
                  </Stack>
                </Stack>
                <Text
                  fontSize={14}
                  fontWeight={500}
                  color={colors.success}
                  style={{ marginTop: matchesMD ? 10 : 28 }}
                  loading={engagementDataLoading}
                >
                  {dashboardCardData?.avgEducationHoursPerUser
                    ? t("per_person_average", {
                        amount: convertNumberToHoursMinutes(
                          dashboardCardData?.avgEducationHoursPerUser!
                        ),
                      })
                    : t("no_data")}
                </Text>
              </InfoCard>
            </Stack>
            {/* most active user */}
            <InfoCard
              style={{ width: matchesMD ? "100%" : "50%" }}
              loading={engagementDataLoading}
            >
              <Title lineHeight="28px">{t("most_active_user")}</Title>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  marginTop: 22,
                  gap: matchesMD ? 30 : 50,
                }}
              >
                {engagementDataLoading ? (
                  <Skeleton
                    variant="circular"
                    style={{ width: 115, minWidth: 115, height: 115 }}
                  />
                ) : (
                  <div
                    style={{
                      width: 115,
                      minWidth: 115,
                      height: 115,
                      borderRadius: "100%",
                      backgroundImage: `url(${dashboardCardData?.mostActiveUser.user?.profileImage}), url(http://cdn.aworld.io/users/${dashboardCardData?.mostActiveUser.user?.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                )}
                <InfoStack
                  title={t("nickname")}
                  loading={engagementDataLoading}
                  value={
                    dashboardCardData?.mostActiveUser.user
                      ? dashboardCardData.mostActiveUser.user.nickname ??
                        dashboardCardData.mostActiveUser.user.uid
                      : t("no_data")
                  }
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  marginTop: 12,
                  gap: matchesMD ? 30 : 50,
                }}
              >
                <InfoStack
                  title={t("total_actions")}
                  loading={engagementDataLoading}
                  value={dashboardCardData?.mostActiveUser.totalActions}
                  percentage={
                    dashboardCardData?.mostActiveUser
                      .avgActionsComparisonPercentage
                      ? t("variation_compared_to_other_users", {
                          variation:
                            "+" +
                            formatNumber(
                              Math.round(
                                dashboardCardData?.mostActiveUser
                                  .avgActionsComparisonPercentage
                              )
                            ),
                        })
                      : t("no_data")
                  }
                  style={{
                    width: "calc(50% - 15px)",
                    maxWidth: 115,
                    minWidth: 115,
                  }}
                />
                <InfoStack
                  title={t("total_episodes")}
                  loading={engagementDataLoading}
                  value={dashboardCardData?.mostActiveUser.totalEpisodes}
                  percentage={
                    dashboardCardData?.mostActiveUser
                      .avgEpisodesComparisonPercentage
                      ? t("variation_compared_to_other_users", {
                          variation:
                            "+" +
                            formatNumber(
                              Math.round(
                                dashboardCardData?.mostActiveUser
                                  .avgEpisodesComparisonPercentage
                              )
                            ),
                        })
                      : t("no_data")
                  }
                  style={{ width: "calc(50% - 15px)" }}
                />
              </Stack>
            </InfoCard>
          </Stack>
        </Card>
      </FadeFromTop>
      {/* challenge in progress */}
      <FadeFromTop
        delay={0.05}
        style={{
          marginTop: 24,
        }}
      >
        <Stack direction={{ lg: "column", xl: "row" }} gap={3}>
          <Card width={matchesXL ? "calc(60% - 12px)" : "100%"}>
            <ButtonBase
              title="Info"
              style={{
                width: 28,
                height: 28,
                position: "absolute",
                right: 24,
                borderRadius: "100%",
              }}
              onClick={() => {
                setChallengeAlertOpen(true)
              }}
            >
              <img
                src={infoPrimaryIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </ButtonBase>
            <Title style={{ maxWidth: "90%" }}>{t("challenge")}</Title>
            {!engagementDataLoading && !challenge ? (
              <Text>{t("no_challenge_for_team")}</Text>
            ) : null}
            {challenge || engagementDataLoading ? (
              <Tabs
                tabs={challengeTabs}
                selectedTab={challengeSelectedTab}
                setSelectedTab={setChallengeSelectedTab}
              />
            ) : null}
            {challenge || engagementDataLoading ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: 362,
                  position: "relative",
                }}
              >
                <AnimatePresence>
                  <motion.div
                    key={challengeSelectedTab}
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                    transition={{
                      scale: { duration: 0.4, type: "spring" },
                      opacity: { duration: 0.1 },
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                  >
                    {challengeSelectedTab === 0 ? (
                      <Stack gap={2}>
                        <Stack direction="row" style={{ gap: 20 }}>
                          <Stack style={{ maxWidth: "60%" }}>
                            <Title
                              fontSize={22}
                              lineHeight="28px"
                              loading={engagementDataLoading}
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {challenge?.document.title}
                            </Title>
                            <Stack
                              direction="row"
                              alignItems="center"
                              style={{ height: 24, marginTop: 12, gap: 7 }}
                            >
                              <img
                                src={peopleLightIcon}
                                style={{ width: 16 }}
                              />
                              <Text
                                color={colors.textSecondary}
                                loading={engagementDataLoading}
                              >
                                {formatNumber(challenge?.challengeUsers)}{" "}
                                {t("participants")}
                              </Text>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              style={{ height: 24, marginTop: 6, gap: 7 }}
                            >
                              <img
                                src={calendarLightIcon}
                                style={{ width: 16 }}
                              />
                              <Text
                                color={colors.textSecondary}
                                loading={engagementDataLoading}
                              >
                                {challenge
                                  ? `${new Date(
                                      challenge.startsAt
                                    ).getDate()} ${t(
                                      months[
                                        new Date(challenge.startsAt).getMonth()
                                      ]
                                    ).slice(0, 3)} - ${new Date(
                                      challenge.endsAt
                                    ).getDate()} ${t(
                                      months[
                                        new Date(challenge.endsAt).getMonth()
                                      ]
                                    ).slice(0, 3)}`
                                  : ""}
                              </Text>
                            </Stack>
                          </Stack>
                        </Stack>
                        <InfoCard loading={engagementDataLoading}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            style={{ height: 25, gap: 9 }}
                          >
                            <img src={trophyIcon} style={{ width: 18 }} />
                            <Title
                              fontSize={18}
                              lineHeight="20px"
                              loading={engagementDataLoading}
                            >
                              {t("goal")}:{" "}
                              {formatNumber(challenge?.targetAmount)}{" "}
                              {challenge?.metricName}
                            </Title>
                          </Stack>
                          <ChallengeProgressBar
                            percentage={
                              challenge ? challenge.reachedPercentage : 0
                            }
                            loading={engagementDataLoading}
                            style={{ marginTop: 10 }}
                          />
                          {engagementDataLoading ? (
                            <Stack style={{ marginTop: 10 }}>
                              <Skeleton
                                variant="text"
                                style={{
                                  width: "100%",
                                  fontSize: matchesMD ? 14 : 16,
                                }}
                              />
                              <Skeleton
                                variant="text"
                                style={{
                                  width: "70%",
                                  fontSize: matchesMD ? 14 : 16,
                                }}
                              />
                            </Stack>
                          ) : (
                            <Text
                              color={colors.textSecondary}
                              style={{
                                marginTop: 10,
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {challenge &&
                              challenge.daysTillTargetReached === 0 ? (
                                t("you_have_reached_goal")
                              ) : (
                                <Trans
                                  i18nKey="challenge_goal_calculation"
                                  values={{
                                    dailyCount: Math.round(
                                      challenge!.avgDailyMetrics
                                    ),
                                    daysCount: challenge?.daysTillTargetReached,
                                    metric:
                                      challenge?.metricUnit === "activity" ||
                                      challenge?.metricUnit === "action" ||
                                      challenge?.metricUnit === "Episodes" ||
                                      challenge?.metricUnit === "friends"
                                        ? challenge.metricName.toLowerCase()
                                        : challenge?.metricUnit,
                                  }}
                                >
                                  with an average of{" "}
                                  <strong>actions per day</strong>, you will
                                  reach your goal within <strong>days!</strong>
                                </Trans>
                              )}
                            </Text>
                          )}
                        </InfoCard>
                        <Button
                          primary
                          disabled={!pastChallengesList.length}
                          size="normal"
                          style={{
                            position: "absolute",
                            bottom: 0,
                            width: "100%",
                          }}
                          onClick={() => {
                            navigate(window.location.pathname + "/challenges")
                          }}
                          loading={engagementDataLoading}
                        >
                          {t("view_past_challenges")}
                        </Button>
                      </Stack>
                    ) : (
                      <Stack gap={3}>
                        <ChallengeLeaderboardTop3
                          loading={engagementDataLoading}
                        />
                        <Stack
                          style={{
                            width: "100%",
                            marginTop: -12,
                          }}
                        >
                          {engagementDataLoading ? (
                            <ChallengeLeaderboardListItemSkeleton />
                          ) : leaderboard[3] ? (
                            <ChallengeLeaderboardListItem
                              place={4}
                              user={leaderboard[3].user}
                              points={leaderboard[3].userPoints}
                              loading={engagementDataLoading}
                            />
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: 66,
                              }}
                            />
                          )}
                          {leaderboard[4] ? (
                            <div
                              style={{
                                width: "100%",
                                height: 2,
                                backgroundColor: colors.backgroundLight,
                              }}
                            />
                          ) : null}
                          {engagementDataLoading ? (
                            <ChallengeLeaderboardListItemSkeleton />
                          ) : leaderboard[4] ? (
                            <ChallengeLeaderboardListItem
                              place={5}
                              user={leaderboard[4].user}
                              points={leaderboard[4].userPoints}
                              loading={engagementDataLoading}
                            />
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: 66,
                              }}
                            />
                          )}
                        </Stack>
                        <Button
                          primary
                          disabled={leaderboard.length <= 5}
                          size="normal"
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: 0,
                          }}
                          onClick={() => {
                            navigate("/engagement/leaderboard")
                          }}
                          loading={engagementDataLoading}
                        >
                          {t("view_all")}
                        </Button>
                      </Stack>
                    )}
                  </motion.div>
                </AnimatePresence>
              </div>
            ) : null}
          </Card>
          <Card gap={2} width={matchesXL ? "calc(40% - 12px)" : "100%"}>
            <ButtonBase
              title="Info"
              style={{
                width: 28,
                height: 28,
                position: "absolute",
                right: 24,
                borderRadius: "100%",
              }}
              onClick={() => {
                setSavingsAlertOpen(true)
              }}
            >
              <img
                src={infoPrimaryIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </ButtonBase>
            <Title>{t("team_savings")}</Title>
            <TeamSavingsCard
              icon1={co2WhiteIcon}
              value1={teamSavingsCardData ? teamSavingsCardData.co2Saving : 0}
              label1={t("kg_co2_saved")}
              icon2={batteryWhiteIcon}
              value2={
                teamSavingsCardData ? teamSavingsCardData.phoneChargeSaving : 0
              }
              label2={t("mobile_battery_charges")}
              loading={engagementDataLoading}
              style={{ marginTop: 8 }}
            />
            <TeamSavingsCard
              icon1={waterWhiteIcon}
              value1={teamSavingsCardData ? teamSavingsCardData.waterSaving : 0}
              label1={t("l_water_saved")}
              icon2={bathtubeWhiteIcon}
              value2={
                teamSavingsCardData ? teamSavingsCardData.bathTubSaving : 0
              }
              label2={t("bathtubes_filled")}
              loading={engagementDataLoading}
            />
            <TeamSavingsCard
              icon1={energyWhiteIcon}
              value1={
                teamSavingsCardData ? teamSavingsCardData.energySaving : 0
              }
              label1={t("kwh_energy_saved")}
              icon2={dishwasherWhiteIcon}
              value2={
                teamSavingsCardData ? teamSavingsCardData.dishWasherSaving : 0
              }
              label2={t("dishwasher_cycles")}
              loading={engagementDataLoading}
            />
          </Card>
        </Stack>
      </FadeFromTop>
      {/* challenge overview */}
      {challenge || engagementDataLoading ? (
        <FadeFromTop
          delay={0.1}
          style={{
            marginTop: 24,
          }}
        >
          <Card style={{ overflowX: "scroll" }}>
            <Stack gap={3} style={{ minWidth: 880 }}>
              <Title>{t("challenge_overview")}</Title>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: 69,
                  position: "relative",
                }}
              >
                <Stack style={{ gap: 12 }}>
                  {challengeOverviewSelectedOption !== 3 && (
                    <Title fontSize={16} lineHeight="19px">
                      {t("progression_view")}
                    </Title>
                  )}
                  <ChallengeOverviewIntervalSelect
                    selectedOption={challengeOverviewSelectedOption}
                    interval={challengeOverviewInterval}
                    setInterval={setChallengeOverviewInterval}
                    loading={engagementDataLoading}
                  />
                </Stack>
                <Stack style={{ position: "absolute", right: 0, gap: 6 }}>
                  <Text
                    fontSize={14}
                    lineHeight="14px"
                    fontWeight={500}
                    color={colors.primary}
                  >
                    {t("show")}
                  </Text>
                  <Select
                    options={challengeOverviewOptions}
                    selectedOption={challengeOverviewSelectedOption}
                    onChange={(newOption) =>
                      setChallengeOverviewSelectedOption(newOption)
                    }
                    popoverWidth={160}
                    style={{ width: 160 }}
                    loading={engagementDataLoading}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" gap={3}>
                <Stack
                  justifyContent="flext-start"
                  style={{ width: "30%", height: 230, gap: 20 }}
                >
                  {/* actions completed */}
                  <InfoCardSelectable
                    size="small"
                    selected={challengeOverviewSelectedItem === 0}
                    onClick={() => {
                      setChallengeOverviewSelectedItem(0)
                    }}
                    loading={engagementDataLoading}
                  >
                    <Stack direction="row" gap={1}>
                      <div
                        className="center"
                        style={{
                          width: 40,
                          minWidth: 40,
                          height: 40,
                          borderRadius: 10,
                          backgroundColor: colors.primary,
                        }}
                      >
                        <img
                          src={actionWhiteIcon}
                          style={{ width: 24 }}
                          alt=""
                        />
                      </div>
                      <Stack
                        justifyContent="center"
                        gap={0.5}
                        style={{ width: "100%", position: "relative" }}
                      >
                        <Text
                          fontSize={12}
                          lineHeight="14px"
                          fontWeight={500}
                          color={colors.textSecondary}
                        >
                          {challenge
                            ? capitalizeFirstCharacter(challenge.metricName)
                            : null}
                        </Text>
                        <Title
                          fontSize={16}
                          lineHeight="19px"
                          loading={engagementDataLoading}
                          skeletonWidth={50}
                        >
                          {formatNumber(
                            dataForChallengeOverviewChart.reduce(
                              (currentValue, item) =>
                                currentValue + item.activityAmount,
                              0
                            )
                          )}
                        </Title>
                      </Stack>
                    </Stack>
                  </InfoCardSelectable>
                </Stack>
                <Stack style={{ width: "70%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: 230,
                      position: "relative",
                    }}
                  >
                    <ChallengeOverviewLineChart
                      selectedItem={challengeOverviewSelectedItem}
                      interval={challengeOverviewInterval}
                      dataForChart={dataForChallengeOverviewChart}
                      setDataForChart={setDataForChallengeOverviewChart}
                      selectedOption={challengeOverviewSelectedOption}
                      loading={engagementDataLoading}
                    />
                  </div>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </FadeFromTop>
      ) : null}
      {/* content overview */}
      <FadeFromTop
        delay={0.15}
        style={{
          marginTop: 24,
        }}
      >
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <ButtonBase
              title="Info"
              style={{
                width: 28,
                height: 28,
                position: "absolute",
                right: 24,
                borderRadius: "100%",
              }}
              onClick={() => {
                setOverviewAlertOpen(true)
              }}
            >
              <img
                src={infoPrimaryIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </ButtonBase>
            <Title>{t("overview_overtime")}</Title>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 69,
                position: "relative",
              }}
            >
              <Stack style={{ gap: 12 }}>
                {contentSelectedOption !== 3 && (
                  <Title fontSize={16} lineHeight="19px">
                    {t("progression_view")}
                  </Title>
                )}
                <OverviewIntervalSelect
                  selectedOption={contentSelectedOption}
                  interval={contentInterval}
                  setInterval={setContentInterval}
                  loading={engagementDataLoading}
                />
              </Stack>
              <Stack style={{ position: "absolute", right: 0, gap: 6 }}>
                <Text
                  fontSize={14}
                  lineHeight="14px"
                  fontWeight={500}
                  color={colors.primary}
                >
                  {t("show")}
                </Text>
                <Select
                  options={contentOptions}
                  selectedOption={contentSelectedOption}
                  onChange={(newOption) => setContentSelectedOption(newOption)}
                  popoverWidth={160}
                  style={{ width: 160 }}
                  loading={engagementDataLoading}
                />
              </Stack>
            </Stack>
            <Stack direction="row" gap={3}>
              <Stack
                justifyContent="flext-start"
                style={{ width: "30%", height: 230, gap: 20 }}
              >
                {/* episodes completed */}
                <InfoCardSelectable
                  size="small"
                  selected={contentSelectedItem === 0}
                  onClick={() => {
                    setContentSelectedItem(0)
                  }}
                  loading={engagementDataLoading}
                >
                  <Stack direction="row" gap={1}>
                    <div
                      className="center"
                      style={{
                        width: 40,
                        minWidth: 40,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: colors.primary,
                      }}
                    >
                      <img src={playWhiteIcon} style={{ width: 15 }} alt="" />
                    </div>
                    <Stack
                      justifyContent="center"
                      gap={0.5}
                      style={{ width: "100%", position: "relative" }}
                    >
                      <Text
                        fontSize={12}
                        lineHeight="14px"
                        fontWeight={500}
                        color={colors.textSecondary}
                      >
                        {t("episodes_completed")}
                      </Text>
                      <Title
                        fontSize={16}
                        lineHeight="19px"
                        loading={engagementDataLoading}
                        skeletonWidth={50}
                      >
                        {formatNumber(
                          dataForChart
                            .filter((item) => item.activityType === "episode")
                            .reduce(
                              (currentValue, item) =>
                                currentValue + item.activityAmount,
                              0
                            )
                        )}
                      </Title>
                      <div
                        role="button"
                        className="center"
                        style={{
                          width: 28,
                          height: 28,
                          borderRadius: "100%",
                          position: "absolute",
                          right: 0,
                        }}
                        onClick={(e) => {
                          if (!engagementDataLoading) {
                            e.stopPropagation()

                            setDataVisibility((current) => [
                              !current[0],
                              current[1],
                            ])
                          }
                        }}
                      >
                        <VisibilityRounded
                          style={{
                            color: colors.primary,
                            fontSize: 18,
                            position: "absolute",
                            opacity: engagementDataLoading
                              ? 0.5
                              : dataVisibility[0]
                              ? 1
                              : 0,
                            transition: "250ms",
                          }}
                        />
                        <VisibilityOffRounded
                          style={{
                            color: colors.primary,
                            fontSize: 18,
                            position: "absolute",
                            opacity:
                              dataVisibility[0] || engagementDataLoading
                                ? 0
                                : 1,
                            transition: "400ms",
                          }}
                        />
                      </div>
                    </Stack>
                  </Stack>
                </InfoCardSelectable>
                {/* actions completed */}
                <InfoCardSelectable
                  size="small"
                  selected={contentSelectedItem === 1}
                  onClick={() => {
                    setContentSelectedItem(1)
                  }}
                  loading={engagementDataLoading}
                >
                  <Stack direction="row" gap={1}>
                    <div
                      className="center"
                      style={{
                        width: 40,
                        minWidth: 40,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: colors.primary,
                      }}
                    >
                      <img src={actionWhiteIcon} style={{ width: 24 }} alt="" />
                    </div>
                    <Stack
                      justifyContent="center"
                      gap={0.5}
                      style={{ width: "100%", position: "relative" }}
                    >
                      <Text
                        fontSize={12}
                        lineHeight="14px"
                        fontWeight={500}
                        color={colors.textSecondary}
                      >
                        {t("actions_completed")}
                      </Text>
                      <Title
                        fontSize={16}
                        lineHeight="19px"
                        loading={engagementDataLoading}
                        skeletonWidth={50}
                      >
                        {formatNumber(
                          dataForChart
                            .filter((item) => item.activityType === "action")
                            .reduce(
                              (currentValue, item) =>
                                currentValue + item.activityAmount,
                              0
                            )
                        )}
                      </Title>
                      <div
                        role="button"
                        className="center"
                        style={{
                          width: 28,
                          height: 28,
                          borderRadius: "100%",
                          position: "absolute",
                          right: 0,
                        }}
                        onClick={(e) => {
                          if (!engagementDataLoading) {
                            e.stopPropagation()

                            setDataVisibility((current) => [
                              current[0],
                              !current[1],
                            ])
                          }
                        }}
                      >
                        <VisibilityRounded
                          style={{
                            color: colors.primary,
                            fontSize: 18,
                            position: "absolute",
                            opacity: engagementDataLoading
                              ? 0.5
                              : dataVisibility[1]
                              ? 1
                              : 0,
                            transition: "250ms",
                          }}
                        />
                        <VisibilityOffRounded
                          style={{
                            color: colors.primary,
                            fontSize: 18,
                            position: "absolute",
                            opacity:
                              dataVisibility[1] || engagementDataLoading
                                ? 0
                                : 1,
                            transition: "400ms",
                          }}
                        />
                      </div>
                    </Stack>
                  </Stack>
                </InfoCardSelectable>
              </Stack>
              <Stack style={{ width: "70%" }}>
                <div
                  style={{
                    width: "100%",
                    height: 230,
                    position: "relative",
                  }}
                >
                  <OverviewLineChart
                    selectedItem={contentSelectedItem}
                    interval={contentInterval}
                    dataForChart={dataForChart}
                    setDataForChart={setDataForChart}
                    dataVisibility={dataVisibility}
                    selectedOption={contentSelectedOption}
                    loading={engagementDataLoading}
                  />
                </div>
              </Stack>
            </Stack>
            <div
              style={{
                width: "100%",
                height: 17,
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                style={{ position: "absolute", right: 0 }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: "100%",
                      backgroundColor: colors.categories.episodes,
                    }}
                  />
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color={colors.textSecondary}
                  >
                    {t("episodes")}
                  </Text>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: "100%",
                      backgroundColor: colors.categories.actions,
                    }}
                  />
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color={colors.textSecondary}
                  >
                    {t("actions")}
                  </Text>
                </Stack>
              </Stack>
            </div>
          </Stack>
        </Card>
      </FadeFromTop>
      {/* by category and team savings */}
      <FadeFromTop
        delay={0.2}
        style={{
          marginTop: 24,
        }}
      >
        <Card gap={0} style={{ overflowX: "scroll" }}>
          <Stack style={{ minWidth: 540 }}>
            <Stack
              direction="row"
              alignItems="center"
              style={{ position: "relative" }}
            >
              <Title>{t("by_categories")}</Title>
              <Title
                style={{ position: "absolute", right: 0 }}
                loading={engagementDataLoading}
              >
                {t("total")}:{" "}
                <span style={{ color: colors.primary }}>
                  {categoriesSelectedTab === 0
                    ? formatNumber(
                        byCategoriesCardData
                          .filter(
                            (item) =>
                              item.activityType === "episode" &&
                              item.activityCategory
                          )
                          .reduce(
                            (accumulator, item) =>
                              accumulator + item.activityAmount,
                            0
                          )
                      )
                    : formatNumber(
                        byCategoriesCardData
                          .filter(
                            (item) =>
                              item.activityType === "action" &&
                              item.activityCategory
                          )
                          .reduce(
                            (accumulator, item) =>
                              accumulator + item.activityAmount,
                            0
                          )
                      )}
                </span>
              </Title>
            </Stack>
            <Tabs
              tabs={categoriesTabs}
              selectedTab={categoriesSelectedTab}
              setSelectedTab={setCategoriesSelectedTab}
              style={{ width: 362, maxWidth: "100%", marginTop: 20 }}
            />
            <Text
              fontSize={14}
              lineHeight="20px"
              color={colors.primary}
              style={{ marginTop: 10 }}
            >
              {categoriesSelectedTab === 0
                ? capitalizeFirstCharacter(t("views"))
                : capitalizeFirstCharacter(t("logs"))}
            </Text>
            <div
              style={{
                width: "100%",
                height: 246,
                position: "relative",
                marginTop: 10,
              }}
            >
              <BarChart
                xTicksFontSize={9}
                data={
                  categoriesSelectedTab === 0
                    ? byCategoriesCardData
                        .filter(
                          (item) =>
                            item.activityType === "episode" &&
                            item.activityCategory
                        )
                        .sort((a, b) => {
                          if (a.activityCategory < b.activityCategory) {
                            return -1
                          } else if (a.activityCategory > b.activityCategory) {
                            return 1
                          }
                          return 0
                        })
                        .map((item) => {
                          return {
                            label: categories.find(
                              (category) =>
                                category.id === item.activityCategory
                            )!.name,
                            value: item.activityAmount,
                            backgroundColor: categories.find(
                              (category) =>
                                category.id === item.activityCategory
                            )!.backTagColor,
                            color: categories.find(
                              (category) =>
                                category.id === item.activityCategory
                            )!.foreColor,
                          }
                        })
                    : byCategoriesCardData
                        .filter(
                          (item) =>
                            item.activityType === "action" &&
                            item.activityCategory
                        )
                        .sort((a, b) => {
                          if (a.activityCategory < b.activityCategory) {
                            return -1
                          } else if (a.activityCategory > b.activityCategory) {
                            return 1
                          }
                          return 0
                        })
                        .map((item) => {
                          return {
                            label: categories.find(
                              (category) =>
                                category.id === item.activityCategory
                            )!.name,
                            value: item.activityAmount,
                            backgroundColor: categories.find(
                              (category) =>
                                category.id === item.activityCategory
                            )!.backTagColor,
                            color: categories.find(
                              (category) =>
                                category.id === item.activityCategory
                            )!.foreColor,
                          }
                        })
                }
                loading={engagementDataLoading}
              />
            </div>
          </Stack>
        </Card>
      </FadeFromTop>
      {/* alerts */}
      <AlertInfo
        open={challengeAlertOpen}
        setOpen={setChallengeAlertOpen}
        title={t("challenge")}
        description={t("challenge_description")}
      />
      <AlertInfo
        open={overviewAlertOpen}
        setOpen={setOverviewAlertOpen}
        title={t("overview_overtime")}
        description={t("overview_overtime_description")}
      />
      <AlertInfo
        open={savingsAlertOpen}
        setOpen={setSavingsAlertOpen}
        title={t("team_savings")}
        description={t("team_savings_description")}
      />
    </PageContainer>
  )
}

export default Engagement
