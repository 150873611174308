import { useContext } from "react"
import Loading from "./views/public/loading"
import LogIn from "./views/public/logIn"
import Main from "./views/private/main"
import { AuthContext } from "./controllers/auth"
import Center from "./components/global/Center"
import Text from "./components/global/Text"
import Button from "./components/global/Button"
import { MainController } from "./controllers/main"
import { ActivitiesController } from "./controllers/activities"
import { HelperController } from "./controllers/helper"
import { FootprintController } from "./controllers/footprint"
import { EducationController } from "./controllers/education"
import { EngagementController } from "./controllers/engagement"

const App = () => {
  const { loading, isAuth, viewNoTeamPage, logOut } = useContext(AuthContext)

  if (loading) {
    return <Loading />
  }

  if (!isAuth) {
    return <LogIn />
  }

  if (viewNoTeamPage) {
    return (
      <Center>
        <Text>No teams</Text>
        <Button onClick={logOut} style={{ width: 100, marginTop: 20 }}>
          Logout
        </Button>
      </Center>
    )
  }

  return (
    <MainController>
      <EngagementController>
        <EducationController>
          <FootprintController>
            <ActivitiesController>
              <HelperController>
                <Main />
              </HelperController>
            </ActivitiesController>
          </FootprintController>
        </EducationController>
      </EngagementController>
    </MainController>
  )
}

export default App
