import { createContext, ReactNode, useContext, useMemo } from "react"
import { MainContext } from "./main"
import { ActivitiesContext } from "./activities"
import { FootprintContext } from "./footprint"
import { EducationContext } from "./education"
import { EngagementContext } from "./engagement"

interface HelperContextInterface {
  loading: boolean
  getData: () => void
}

const HelperContext = createContext<HelperContextInterface>({
  loading: true,
  getData: () => {},
})

const HelperController = ({ children }: { children: ReactNode }) => {
  const { mainDataLoading, getMainData } = useContext(MainContext)
  const { activitiesDataLoading, getActivitiesData } =
    useContext(ActivitiesContext)
  const { footprintDataLoading, getFootprintData } =
    useContext(FootprintContext)
  const { educationDataLoading, getEducationData } =
    useContext(EducationContext)
  const { engagementDataLoading, getEngagementData } =
    useContext(EngagementContext)

  // states
  const loading = useMemo(
    () =>
      mainDataLoading ||
      activitiesDataLoading ||
      footprintDataLoading ||
      educationDataLoading ||
      engagementDataLoading,
    [
      mainDataLoading,
      activitiesDataLoading,
      footprintDataLoading,
      educationDataLoading,
      engagementDataLoading,
    ]
  )

  // functions
  const getData = () => {
    // get data from main controller
    getMainData()

    // get specific data only if user is in the correct route
    const pathname = window.location.pathname
    if (pathname.startsWith("/activities")) {
      getActivitiesData()
    } else if (pathname.startsWith("/footprint")) {
      getFootprintData()
    } else if (pathname.startsWith("/education")) {
      getEducationData()
    } else if (pathname.startsWith("/engagement")) {
      getEngagementData()
    }
  }

  return (
    <HelperContext.Provider
      value={{
        loading,
        getData,
      }}
    >
      {children}
    </HelperContext.Provider>
  )
}
export { HelperController, HelperContext }
