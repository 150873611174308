import { t } from "i18next"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import PageContainer from "../../../components/global/PageContainer"
import Table from "../../../components/global/Table"
import { useContext, useMemo, useRef, useState } from "react"
import { colors } from "../../../services/config/colors"
import TableRow from "../../../components/global/TableRow"
import { Stack, TableCell } from "@mui/material"
import Text from "../../../components/global/Text"
import { MainContext } from "../../../controllers/main"
import CompactFilters from "../../../components/global/CompactFilters"
import {
  formatNumber,
  roundWithDecimalPlaces,
} from "../../../services/utils/utils"
import { AuthContext } from "../../../controllers/auth"
import { TeamStakeholderType } from "../../../services/config/enum"
import { EngagementContext } from "../../../controllers/engagement"

const Leaderboard = () => {
  const { team } = useContext(AuthContext)
  const { countriesList } = useContext(MainContext)
  const {
    engagementDataLoading,
    leaderboard,
    leaderboardNextToken,
    loadMoreLeaderboardItems,
  } = useContext(EngagementContext)

  // table heading items
  const headingItems = useMemo(() => {
    return [
      <>
        {t("users")}{" "}
        <span style={{ color: colors.textSecondary }}>
          ({leaderboard.length})
        </span>
      </>,
      t("location"),
      t("total_points"),
    ]
  }, [leaderboard])

  // page container onScroll event to fetch new items on scroll
  const fetchingMoreItems = useRef<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  const onScroll = async () => {
    const mainContainer = document.getElementById("page-container")!

    if (
      mainContainer.scrollTop + 50 >=
        mainContainer.scrollHeight - window.innerHeight &&
      !fetchingMoreItems.current &&
      leaderboardNextToken &&
      !engagementDataLoading
    ) {
      fetchingMoreItems.current = true
      setLoadingMore(true)

      await loadMoreLeaderboardItems()

      fetchingMoreItems.current = false
      setLoadingMore(false)
    }
  }

  return (
    <PageContainer
      title={t("leaderboard")}
      breadcrumbs={[
        { title: t("engagement"), path: "/engagement" },
        { title: t("leaderboard") },
      ]}
      withFilters={false}
      paddingTop={24}
      variant="compact"
      onScroll={onScroll}
      csvData={[
        [t("position"), t("nickname"), t("location"), t("total_points")],
        ...leaderboard.map((item, index) => [
          (index + 1).toString(),
          item.user.nickname ?? item.user.uid,
          countriesList.some((country) => country.id === item.user.country)
            ? countriesList.find((country) => country.id === item.user.country)!
                .name
            : "",
          formatNumber(item.userPoints),
        ]),
      ]}
      csvFileName="leaderboard"
    >
      <div
        style={{ width: "100%", height: 1, backgroundColor: colors.stroke }}
      />
      <CompactFilters />
      <FadeFromTop style={{ marginTop: 40 }}>
        <Table
          headingItems={headingItems}
          loading={engagementDataLoading}
          loadingMore={loadingMore}
        >
          {leaderboard.map((item, index) => (
            <TableRow index={index} key={index}>
              <TableCell>
                <Stack direction="row" alignItems="center" style={{ gap: 20 }}>
                  <Text fontSize={14} fontWeight={500}>
                    {index + 1}°
                  </Text>
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "100%",
                      backgroundImage: `url(${item.user.profileImage}), url(http://cdn.aworld.io/users/${item.user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Text fontSize={14} fontWeight={500}>
                    {team?.stakeholderType === TeamStakeholderType.employees
                      ? `${item.user.first_name} ${item.user.last_name}`
                      : item.user.nickname ?? item.user.uid}
                  </Text>
                </Stack>
              </TableCell>
              <TableCell>
                <Text fontSize={14} fontWeight={500}>
                  {
                    countriesList.find(
                      (country) => country.id === item.user.country
                    )?.name
                  }
                </Text>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Text fontSize={14} fontWeight={500}>
                    {formatNumber(item.userPoints)}
                  </Text>
                  {item.userPointsDeltaPercentage ? (
                    <Text
                      fontSize={14}
                      fontWeight={500}
                      color={
                        item.userPointsDeltaPercentage < 0
                          ? colors.error
                          : colors.success
                      }
                    >
                      {item.userPointsDeltaPercentage >= 0 ? "+" : null}
                      {formatNumber(
                        roundWithDecimalPlaces(
                          item.userPointsDeltaPercentage,
                          1
                        )
                      )}
                      % {t("this_week")}
                    </Text>
                  ) : null}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </FadeFromTop>
    </PageContainer>
  )
}

export default Leaderboard
