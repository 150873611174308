import { ButtonBase, Stack, useMediaQuery, useTheme } from "@mui/material"
import Center from "../../components/global/Center"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../controllers/auth"
import awLogoIcon from "../../assets/images/aw-logo-icon.svg"
import Title from "../../components/global/Title"
import { colors } from "../../services/config/colors"
import { t } from "i18next"
import loginBackground from "../../assets/images/login-background.svg"
import {
  defaultBoxShadow,
  defaultTransition,
} from "../../services/config/constants"
import Text from "../../components/global/Text"
import Button from "../../components/global/Button"
import SocialButton from "../../components/global/SocialButton"
import FadeFromTop from "../../components/animations/FadeFromTop"
import Input from "../../components/global/Input"
import OtpInput from "react-otp-input"
import "../../styles/logIn.css"
import Alert from "../../components/global/Alert"
import { signInWithRedirect } from "aws-amplify/auth"

const LogIn = () => {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"))
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"))
  const { sendCode, confirmCode, setIsAuth } = useContext(AuthContext)

  // states
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false)
  const [loadingFacebook, setLoadingFacebook] = useState<boolean>(false)
  const [loadingApple, setLoadingApple] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [codeSent, setCodeSent] = useState<boolean>(false)

  // confirm code when length is 6
  useEffect(() => {
    const callConfirmCode = async () => {
      if (code.length === 6) {
        setLoading(true)

        const result = await confirmCode(code)

        if (result) {
          setIsAuth(true)
        } else {
          setErrorType("code")
          setErrorAlertOpen(true)
          setCode("")
        }
        setLoading(false)
      }
    }

    callConfirmCode()
  }, [code])

  // something went wrong alert
  const [errorAlertOpen, setErrorAlertOpen] = useState<boolean>(false)
  const [errorType, setErrorType] = useState<"email" | "code">("email")

  return (
    <Center
      style={{
        position: "relative",
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* logo */}
      {matchesLG && (
        <Stack
          direction="row"
          gap={2}
          style={{
            position: "absolute",
            top: 45,
            left: 40,
          }}
        >
          <img src={awLogoIcon} alt="AWorld" style={{ height: 38 }} />
          <Title
            fontSize={16}
            lineHeight="20px"
            fontWeight={900}
            color={colors.primaryLogo}
            style={{ width: 200, textTransform: "uppercase" }}
          >
            {t("impact_management_platform")}
          </Title>
        </Stack>
      )}
      {/* main card */}
      {codeSent ? (
        <FadeFromTop key="code" style={{ maxWidth: "100%" }}>
          <Stack
            alignItems="center"
            style={{
              width: 565,
              maxWidth: "100%",
              height: "auto",
              backgroundColor: colors.backgroundWhite,
              borderRadius: 16,
              boxShadow: defaultBoxShadow,
              paddingTop: 42,
              paddingBottom: 42,
              paddingInline: matchesSM ? 60 : 20,
            }}
          >
            <img src={awLogoIcon} alt="AWorld" style={{ height: 38 }} />
            <Title fontSize={24} style={{ marginTop: 38 }}>
              {t("check_your_email")}
            </Title>
            <Text fontSize={16} fontWeight={500} style={{ marginTop: 12 }}>
              {t("enter_the_code_sent_to", { email: email })}
            </Text>
            <form
              style={{ width: "100%", marginTop: 46 }}
              onSubmit={async (e) => {
                e.preventDefault()

                if (code.length === 6) {
                  setLoading(true)

                  const result = await confirmCode(code)

                  if (result) {
                    setIsAuth(true)
                  } else {
                    setErrorType("code")
                    setErrorAlertOpen(true)
                    setCode("")
                  }
                  setLoading(false)
                }
              }}
            >
              <OtpInput
                value={code}
                onChange={setCode}
                numInputs={6}
                renderSeparator={<span style={{ minWidth: 20 }} />}
                renderInput={(props) => <input {...props} disabled={loading} />}
                inputType="number"
                shouldAutoFocus
                inputStyle={loading ? "otp-input-disabled" : "otp-input"}
                containerStyle={{
                  width: "100%",
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={1}
                style={{ marginTop: 44 }}
              >
                <Text fontSize={14} fontWeight={500}>
                  {t("didnt_receive_a_code")}
                </Text>
                <ButtonBase
                  disableRipple
                  disabled={loading}
                  aria-label={t("send_it_again")}
                  onClick={async () => {
                    setLoading(true)

                    await sendCode(email)

                    setLoading(false)
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color={colors.primary}
                    style={{
                      transition: defaultTransition,
                      opacity: loading ? 0.4 : 1,
                    }}
                  >
                    {t("send_it_again")}
                  </Text>
                </ButtonBase>
              </Stack>
              <Button
                primary
                disabled={code.length < 6}
                loading={loading}
                size="normal"
                style={{ width: "100%", marginTop: 42 }}
                submit
              >
                {t("log_in")}
              </Button>
            </form>
            <Button
              size="normal"
              disabled={loading}
              withShadow={false}
              withBorder
              style={{ width: "100%", marginTop: 20 }}
              onClick={() => {
                setCodeSent(false)
              }}
            >
              {t("back")}
            </Button>
          </Stack>
        </FadeFromTop>
      ) : (
        <FadeFromTop key="email" style={{ maxWidth: "100%" }}>
          <Stack
            alignItems="center"
            style={{
              width: 565,
              maxWidth: "100%",
              height: "auto",
              backgroundColor: colors.backgroundWhite,
              borderRadius: 16,
              boxShadow: defaultBoxShadow,
              paddingTop: 42,
              paddingBottom: 42,
              paddingInline: matchesSM ? 60 : 20,
            }}
          >
            <img src={awLogoIcon} alt="AWorld" style={{ height: 38 }} />
            <Title fontSize={24} style={{ marginTop: 38 }}>
              {t("welcome")}
            </Title>
            <Text fontSize={16} fontWeight={500} style={{ marginTop: 12 }}>
              {t("please_enter_your_email_address")}
            </Text>
            <form
              style={{ width: "100%", marginTop: 46 }}
              onSubmit={async (e) => {
                e.preventDefault()

                if (email && email.includes("@")) {
                  setLoading(true)

                  const result = await sendCode(email)

                  if (result) {
                    setCodeSent(true)
                  } else {
                    setErrorType("email")
                    setErrorAlertOpen(true)
                  }
                  setLoading(false)
                }
              }}
            >
              <Input
                value={email}
                placeholder={t("email")}
                onChange={(e) => {
                  setEmail(e.target.value.trim())
                }}
                disabled={
                  loading || loadingGoogle || loadingFacebook || loadingApple
                }
              />
              <Button
                primary
                loading={loading}
                size="normal"
                disabled={
                  !email ||
                  !email.includes("@") ||
                  loadingGoogle ||
                  loadingFacebook ||
                  loadingApple
                }
                style={{ width: "100%", marginTop: 20 }}
                submit
              >
                {t("continue")}
              </Button>
            </form>
            <Stack
              direction="row"
              alignItems="center"
              style={{ width: "100%", height: 17, gap: 30, marginTop: 35 }}
            >
              <div
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: colors.textSecondary,
                }}
              />
              <Text
                fontSize={14}
                lineHeight="17px"
                fontWeight={500}
                color={colors.textSecondary}
              >
                {t("or")}
              </Text>
              <div
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: colors.textSecondary,
                }}
              />
            </Stack>
            <SocialButton
              type="apple"
              loading={loadingApple}
              disabled={loading || loadingGoogle || loadingFacebook}
              style={{ width: "100%", marginTop: 35 }}
              onClick={() => {
                setLoadingApple(true)

                signInWithRedirect({
                  provider: "Apple",
                })
              }}
            >
              {t("log_in_with", { provider: "Apple" })}
            </SocialButton>
            <SocialButton
              type="google"
              loading={loadingGoogle}
              disabled={loading || loadingApple || loadingFacebook}
              style={{ width: "100%", marginTop: 20 }}
              onClick={() => {
                setLoadingGoogle(true)

                signInWithRedirect({
                  provider: "Google",
                })
              }}
            >
              {t("log_in_with", { provider: "Google" })}
            </SocialButton>
            <SocialButton
              type="facebook"
              loading={loadingFacebook}
              disabled={loading || loadingGoogle || loadingApple}
              style={{ width: "100%", marginTop: 20 }}
              onClick={() => {
                setLoadingFacebook(true)

                signInWithRedirect({
                  provider: "Facebook",
                })
              }}
            >
              {t("log_in_with", { provider: "Facebook" })}
            </SocialButton>
          </Stack>
        </FadeFromTop>
      )}
      {/* something went wrong alert */}
      <Alert
        open={errorAlertOpen}
        title={t("error_title")}
        description={
          errorType === "email" ? t("incorrect_email") : t("incorrect_code")
        }
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setErrorAlertOpen(false)
        }}
      />
    </Center>
  )
}

export default LogIn
