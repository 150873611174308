import { t } from "i18next"
import PageContainer from "../../../components/global/PageContainer"
import { Stack, TableCell } from "@mui/material"
import { colors } from "../../../services/config/colors"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import Table from "../../../components/global/Table"
import TableRow from "../../../components/global/TableRow"
import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../../controllers/main"
import Text from "../../../components/global/Text"
import { useSearchParams } from "react-router-dom"
import CompactFilters from "../../../components/global/CompactFilters"
import { formatNumber } from "../../../services/utils/utils"
import { EducationContext } from "../../../controllers/education"

const AllSdgs = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { sdgs } = useContext(MainContext)
  const { educationDataLoading, topSdgsList } = useContext(EducationContext)

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(
    searchParams.has("tab") ? parseInt(searchParams.get("tab")!) : 0
  )

  // update query param when selectedTab changes
  useEffect(() => {
    searchParams.set("tab", selectedTab.toString())
    setSearchParams(searchParams)
  }, [selectedTab])

  // table heading items
  const headingItems = useMemo(() => {
    return [
      <>
        {t("sdgs")}{" "}
        <span style={{ color: colors.textSecondary }}>
          (
          {
            topSdgsList
              .filter((item) => sdgs.some((sdg) => sdg.id === item.activitySdg))
              .filter((item) =>
                selectedTab === 0
                  ? item.type === "episode"
                  : item.type === "action"
              ).length
          }
          )
        </span>
      </>,
      t("total_visualizations"),
    ]
  }, [topSdgsList, selectedTab])

  return (
    <PageContainer
      title={t("all_sdgs")}
      breadcrumbs={[
        { title: t("education"), path: "/education" },
        { title: t("all_sdgs") },
      ]}
      withFilters={false}
      withTabs
      tabs={[t("episodes"), t("actions")]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      paddingTop={0}
      variant="compact"
      csvData={[
        [t("position"), t("sdg"), t("total_visualizations")],
        ...topSdgsList
          .filter((item) => sdgs.some((sdg) => sdg.id === item.activitySdg))
          .filter((item) =>
            selectedTab === 0 ? item.type === "episode" : item.type === "action"
          )
          .map((item, index) => [
            (index + 1).toString(),
            `SDG ${parseInt(
              sdgs.find((sdg) => sdg.id === item.activitySdg)!.code
            )} - ${sdgs.find((sdg) => sdg.id === item.activitySdg)?.name}`,
            formatNumber(item.activityAmount),
          ]),
      ]}
      csvFileName={selectedTab === 0 ? "stories_sdgs" : "actions_sdgs"}
    >
      <CompactFilters />
      <FadeFromTop style={{ marginTop: 40 }}>
        <Table headingItems={headingItems} loading={educationDataLoading}>
          {topSdgsList
            .filter((item) => sdgs.some((sdg) => sdg.id === item.activitySdg))
            .filter((item) =>
              selectedTab === 0
                ? item.type === "episode"
                : item.type === "action"
            )
            .map((item, index) => (
              <TableRow index={index} key={index}>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ gap: 20 }}
                  >
                    <Text fontSize={14} fontWeight={500}>
                      {index + 1}°
                    </Text>
                    <img
                      src={
                        sdgs.find((sdg) => sdg.id === item.activitySdg)?.image
                      }
                      style={{
                        width: 30,
                        minWidth: 30,
                        height: 30,
                        borderRadius: 4,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      alt=""
                    />
                    <Text fontSize={14} fontWeight={500}>
                      {`SDG ${parseInt(
                        sdgs.find((sdg) => sdg.id === item.activitySdg)!.code
                      )} - ${
                        sdgs.find((sdg) => sdg.id === item.activitySdg)?.name
                      }`}
                    </Text>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Text fontSize={14} fontWeight={500}>
                    {formatNumber(item.activityAmount)}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </FadeFromTop>
    </PageContainer>
  )
}

export default AllSdgs
