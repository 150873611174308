import { Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { CSSProperties } from "react"
import Title from "../global/Title"
import Text from "../global/Text"

const BenchmarkCard = ({
  title,
  description,
  image,
  loading,
  style,
}: {
  title: string
  description: string
  image: string
  loading: boolean
  style?: CSSProperties
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      style={{
        width: "100%",
        height: 61,
        paddingInline: 14,
        borderRadius: 8,
        backgroundColor: colors.background,
        ...style,
      }}
    >
      <img
        src={image}
        style={{
          width: 50,
          minWidth: 50,
          height: 50,
          objectFit: "cover",
          objectPosition: "center",
        }}
        alt=""
      />
      <Stack style={{ gap: 5 }}>
        <Title
          fontSize={20}
          lineHeight="20px"
          color={colors.primary}
          loading={loading}
          skeletonWidth={100}
        >
          {title}
        </Title>
        <Text
          fontSize={14}
          lineHeight="14px"
          color={colors.textSecondary}
          loading={loading}
          skeletonWidth={150}
        >
          {description}
        </Text>
      </Stack>
    </Stack>
  )
}

export default BenchmarkCard
