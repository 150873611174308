import { Skeleton, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/Title"
import { useContext } from "react"
import User from "../../models/user"
import { formatNumber } from "../../services/utils/utils"
import { AuthContext } from "../../controllers/auth"
import { TeamStakeholderType } from "../../services/config/enum"

const ChallengeLeaderboardListItem = ({
  place,
  user,
  points,
  loading,
}: {
  place: number
  user: User
  points: number
  loading: boolean
}) => {
  const { team } = useContext(AuthContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: 66,
        paddingLeft: 18,
        position: "relative",
      }}
    >
      {loading ? (
        <Skeleton
          variant="circular"
          style={{ width: 25, height: 24, borderRadius: 4 }}
        />
      ) : (
        <div
          style={{
            width: "auto",
            height: 24,
            paddingInline: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.primary,
            borderRadius: 4,
          }}
        >
          <Title fontSize={14} color={colors.textWhite}>
            {place}°
          </Title>
        </div>
      )}
      {loading ? (
        <Skeleton
          variant="circular"
          style={{ width: 32, minWidth: 32, height: 32, marginLeft: 17 }}
        />
      ) : (
        <div
          style={{
            width: 32,
            minWidth: 32,
            height: 32,
            borderRadius: "100%",
            backgroundImage: `url(${user.profileImage}), url(http://cdn.aworld.io/users/${user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginLeft: 17,
          }}
        />
      )}
      <Title
        fontSize={14}
        style={{ maxWidth: "calc(100% - 170px)", marginLeft: 15 }}
        loading={loading}
        ellipsis
      >
        {team?.stakeholderType === TeamStakeholderType.employees
          ? `${user.first_name} ${user.last_name}`
          : user.nickname ?? user.uid}
      </Title>
      <Title
        fontSize={14}
        style={{ position: "absolute", right: 18 }}
        loading={loading}
        skeletonWidth={35}
      >
        {formatNumber(points)} pt
      </Title>
    </Stack>
  )
}

export default ChallengeLeaderboardListItem
