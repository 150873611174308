import { Stack } from "@mui/material"
import InfoCard from "../global/InfoCard"
import { colors } from "../../services/config/colors"
import analyticsIcon from "../../assets/icons/analytics.svg"
import Text from "../global/Text"
import Title from "../global/Title"

const LifestyleCard = ({
  title,
  value,
  icon = analyticsIcon,
  onClick,
  loading,
}: {
  title: string
  value: string
  icon?: string
  onClick?: () => void
  loading: boolean
}) => {
  return (
    <InfoCard size="small" onClick={onClick} loading={loading}>
      <Stack direction="row" alignItems="center" gap={1}>
        <div
          className="center"
          style={{
            width: 40,
            height: 40,
            borderRadius: 10,
            backgroundColor: colors.primary,
          }}
        >
          <img src={icon} />
        </div>
        <Stack>
          <Text
            fontSize={14}
            lineHeight="16px"
            fontWeight={500}
            color={colors.textSecondary}
          >
            {title}
          </Text>
          <Title
            fontSize={16}
            lineHeight="19px"
            style={{ marginTop: 4 }}
            loading={loading}
          >
            {value}
          </Title>
        </Stack>
      </Stack>
    </InfoCard>
  )
}

export default LifestyleCard
