import { createContext, ReactNode, useContext, useState } from "react"
import { MainContext } from "./main"
import { AuthContext } from "./auth"
import {
  deepCopy,
  logger,
  roundWithDecimalPlaces,
  Status,
} from "../services/utils/utils"
import DashboardCardData from "../models/dashboardCardData"
import i18next, { t } from "i18next"
import ChallengeLeaderboardItem from "../models/challengeLeaderboardItem"
import { useLazyQuery } from "@apollo/client"
import {
  challengesPastList,
  dashboardActivitiesByCategoryList,
  dashboardActivityOverviewList,
  dashboardAverageUserGet,
  dashboardChallengeGet,
  dashboardChallengeLeaderboardGet,
  dashboardDailyChallengeOverviewGet,
  dashboardMostActiveUserGet,
  dashboardSavingsGet,
  dashboardTotalEducationHoursGet,
  dashboardTotalEducationHoursUsersGet,
  dashboardTotalUsersGet,
} from "../services/graphql/queries"
import Challenge from "../models/challenge"
import PastChallenge from "../models/pastChallenge"
import User from "../models/user"
import ChallengeOverviewDataItem from "../models/challengeOverviewDataItem"
import OverviewCardDataItem from "../models/overviewCardDataItem"
import ByCategoryCardDataItem from "../models/byCategoryCardDataItem"
import TeamSavingsCardDataItem from "../models/teamSavingsCardDataItem"

interface EngagementContextInterface {
  engagementDataLoading: boolean
  dashboardCardData: DashboardCardData | null
  challenge: Challenge | null
  pastChallengesList: PastChallenge[]
  leaderboard: ChallengeLeaderboardItem[]
  leaderboardNextToken: string | null
  educationHoursUsersList: {
    totalEducationHours: number
    user: User
  }[]
  educationHoursUsersListNextToken: string | null
  challengeOverviewData: ChallengeOverviewDataItem[]
  overviewCardData: OverviewCardDataItem[]
  byCategoriesCardData: ByCategoryCardDataItem[]
  teamSavingsCardData: TeamSavingsCardDataItem | null
  getPastChallengeLeaderboard: (
    teamId: string,
    challenge: string,
    nextToken?: string
  ) => Promise<{ items: ChallengeLeaderboardItem[]; nextToken?: string }>
  loadMoreLeaderboardItems: () => Promise<boolean>
  loadMoreEducationHoursUsersListItems: () => Promise<boolean>
  getEngagementData: () => void
}

const EngagementContext = createContext<EngagementContextInterface>({
  engagementDataLoading: true,
  dashboardCardData: null,
  challenge: null,
  pastChallengesList: [],
  leaderboard: [],
  leaderboardNextToken: null,
  educationHoursUsersList: [],
  educationHoursUsersListNextToken: null,
  challengeOverviewData: [],
  overviewCardData: [],
  byCategoriesCardData: [],
  teamSavingsCardData: null,
  getPastChallengeLeaderboard: async () => {
    return { items: [] }
  },
  loadMoreLeaderboardItems: async () => true,
  loadMoreEducationHoursUsersListItems: async () => true,
  getEngagementData: () => {},
})

const EngagementController = ({ children }: { children: ReactNode }) => {
  const { team } = useContext(AuthContext)
  const { getInput, setError, setErrorMessage } = useContext(MainContext)

  // states
  const [engagementDataLoading, setEngagementDataLoading] =
    useState<boolean>(false)
  const [dashboardCardData, setDashboardCardData] =
    useState<DashboardCardData | null>(null)
  const [challenge, setChallenge] = useState<Challenge | null>(null)
  const [pastChallengesList, setPastChallengesList] = useState<PastChallenge[]>(
    []
  )
  const [leaderboard, setLeaderboard] = useState<ChallengeLeaderboardItem[]>([])
  const [leaderboardNextToken, setLeaderboardNextToken] = useState<
    string | null
  >(null)
  const [educationHoursUsersList, setEducationHoursUsersList] = useState<
    {
      totalEducationHours: number
      user: User
    }[]
  >([])
  const [
    educationHoursUsersListNextToken,
    setEducationHoursUsersListNextToken,
  ] = useState<string | null>(null)
  const [challengeOverviewData, setChallengeOverviewData] = useState<
    ChallengeOverviewDataItem[]
  >([])
  const [overviewCardData, setOverviewCardData] = useState<
    OverviewCardDataItem[]
  >([])
  const [byCategoriesCardData, setByCategoriesCardData] = useState<
    ByCategoryCardDataItem[]
  >([])
  const [teamSavingsCardData, setTeamSavingsCardData] =
    useState<TeamSavingsCardDataItem | null>(null)

  // queries
  const [dashboardTotalUsersGetQuery] = useLazyQuery(dashboardTotalUsersGet)
  const [dashboardAverageUserGetQuery] = useLazyQuery(dashboardAverageUserGet)
  const [dashboardMostActiveUserGetQuery] = useLazyQuery(
    dashboardMostActiveUserGet
  )
  const [dashboardChallengeGetQuery] = useLazyQuery(dashboardChallengeGet)
  const [challengesPastListQuery] = useLazyQuery(challengesPastList)
  const [dashboardChallengeLeaderboardGetQuery] = useLazyQuery(
    dashboardChallengeLeaderboardGet
  )
  const [dashboardTotalEducationHoursUsersGetQuery] = useLazyQuery(
    dashboardTotalEducationHoursUsersGet
  )
  const [dashboardDailyChallengeOverviewGetQuery] = useLazyQuery(
    dashboardDailyChallengeOverviewGet
  )
  const [dashboardActivityOverviewListQuery] = useLazyQuery(
    dashboardActivityOverviewList
  )
  const [dashboardActivitiesByCategoryListQuery] = useLazyQuery(
    dashboardActivitiesByCategoryList
  )
  const [dashboardSavingsGetQuery] = useLazyQuery(dashboardSavingsGet)
  const [dashboardTotalEducationHoursGetQuery] = useLazyQuery(
    dashboardTotalEducationHoursGet
  )

  // get dashboard card
  const getDashboardCardData = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY totalUsersGet")
      logger(Status.Api, "[ENG] QUERY totalEducationHoursGet")
      logger(Status.Api, "[ENG] QUERY averageUserGet")
      logger(Status.Api, "[ENG] QUERY mostActiveUserGet")

      const input = getInput(team!.id)

      const result = await Promise.all([
        dashboardTotalUsersGetQuery({
          variables: { input: input },
        }),
        dashboardTotalEducationHoursGetQuery({
          variables: { input: input },
        }),
        dashboardAverageUserGetQuery({
          variables: { input: input },
        }),
        dashboardMostActiveUserGetQuery({
          variables: { input: input },
        }),
      ])
      const dataToSet: DashboardCardData = {
        totalUsers: result[0].data.dashboardTotalUsersGet
          ? result[0].data.dashboardTotalUsersGet.users
          : null,
        activeUsersPercentage: result[0].data.dashboardTotalUsersGet
          ? roundWithDecimalPlaces(
              result[0].data.dashboardTotalUsersGet.activeUsersPercentage * 100,
              1
            )
          : null,
        totalEducationHours: result[1].data.dashboardTotalEducationHoursGet
          ? Math.round(
              result[1].data.dashboardTotalEducationHoursGet.totalEducationHours
            )
          : null,
        avgEducationHoursPerUser: result[1].data.dashboardTotalEducationHoursGet
          ? roundWithDecimalPlaces(
              result[1].data.dashboardTotalEducationHoursGet
                .avgEducationHoursPerUser,
              2
            )
          : null,
        averageUser: {
          country: result[2].data.dashboardAverageUserGet
            ? result[2].data.dashboardAverageUserGet.country
            : null,
          percentage: result[2].data.dashboardAverageUserGet
            ? roundWithDecimalPlaces(
                result[2].data.dashboardAverageUserGet.percentage * 100,
                1
              )
            : null,
        },
        mostActiveUser: result[3].data.dashboardMostActiveUserGet
          ? {
              sub: result[3].data.dashboardMostActiveUserGet.sub,
              activityHour:
                result[3].data.dashboardMostActiveUserGet.activityHour,
              sameActivityHoursPercentage: roundWithDecimalPlaces(
                result[3].data.dashboardMostActiveUserGet
                  .sameActiveHourPercentage * 100,
                1
              ),
              totalActions:
                result[3].data.dashboardMostActiveUserGet.userActions,
              avgActionsComparisonPercentage: roundWithDecimalPlaces(
                result[3].data.dashboardMostActiveUserGet
                  .avgActionsComparisonPercentage * 100,
                1
              ),
              totalEpisodes:
                result[3].data.dashboardMostActiveUserGet.userEpisodes,
              avgEpisodesComparisonPercentage: roundWithDecimalPlaces(
                result[3].data.dashboardMostActiveUserGet
                  .avgEpisodesComparisonPercentage * 100,
                1
              ),
              user: result[3].data.dashboardMostActiveUserGet.user,
            }
          : {
              sub: null,
              activityHour: null,
              sameActivityHoursPercentage: null,
              totalActions: null,
              avgActionsComparisonPercentage: null,
              totalEpisodes: null,
              avgEpisodesComparisonPercentage: null,
              user: null,
            },
      }
      logger(Status.Info, "[ENG] dashboard card data", dataToSet)

      setDashboardCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Dashboard Card`)
      setDashboardCardData(null)

      return false
    }
  }

  // get challenge card
  const getChallengeCardData = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY challengeGet")
      logger(Status.Api, "[ENG] QUERY challengeLeaderboardGet")

      const input = getInput(team!.id)

      const result = await Promise.all([
        dashboardChallengeGetQuery({
          variables: { input: input },
        }),
        dashboardChallengeLeaderboardGetQuery({
          variables: { input: { ...input, limit: 50 } },
        }),
      ])

      // parse data
      const challengeDataToSet = result[0].data.dashboardChallengeGet
        ? {
            ...result[0].data.dashboardChallengeGet,
            reachedPercentage: roundWithDecimalPlaces(
              result[0].data.dashboardChallengeGet.reachedPercentage * 100,
              1
            ),
            document: result[0].data.dashboardChallengeGet.challenge.document,
            metricName:
              result[0].data.dashboardChallengeGet.challenge.metric.name,
            metricUnit:
              result[0].data.dashboardChallengeGet.challenge.metric.unit,
          }
        : null
      let leaderboardDataToSet = deepCopy(
        result[1].data.dashboardChallengeLeaderboardGet.items
      )
      leaderboardDataToSet = leaderboardDataToSet.filter(
        (item: any) =>
          item.user.first_name !== "deleted" &&
          item.user.last_name !== "deleted"
      )
      leaderboardDataToSet.forEach((item: any) => {
        if (item.userPointsDeltaPercentage) {
          item.userPointsDeltaPercentage = roundWithDecimalPlaces(
            item.userPointsDeltaPercentage * 100,
            1
          )
        }
      })

      logger(Status.Info, "[ENG] challenge", challengeDataToSet)
      logger(Status.Info, "[ENG] challenge leaderboard", leaderboardDataToSet)

      setChallenge(challengeDataToSet)
      setLeaderboard(leaderboardDataToSet)
      setLeaderboardNextToken(
        result[1].data.dashboardChallengeLeaderboardGet.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Challenge`)
      setChallenge(null)
      setLeaderboard([])
      setLeaderboardNextToken(null)

      return false
    }
  }

  // get past challenges
  const getPastChallengesList = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY challengesPastList")

      const { data } = await challengesPastListQuery({
        variables: { input: { teamId: team!.id, lang: i18next.language } },
      })

      logger(Status.Info, "[ENG] past challenges list", data.challengesPastList)

      setPastChallengesList(data.challengesPastList)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Past Challenges`)
      setPastChallengesList([])

      return false
    }
  }

  // get past challenge leaderboard
  const getPastChallengeLeaderboard = async (
    teamId: string,
    challengeId: string,
    nextToken?: string
  ) => {
    try {
      logger(Status.Api, "[ENG] QUERY challengeLeaderboardGet")

      const { data } = await dashboardChallengeLeaderboardGetQuery({
        variables: {
          input: {
            teamId,
            challengeId,
            lang: i18next.language,
            nextToken,
            limit: 50,
          },
        },
      })

      logger(
        Status.Info,
        `[ENG] challenge ${challengeId} leaderboard`,
        data.dashboardChallengeLeaderboardGet.items
      )

      return {
        items: data.dashboardChallengeLeaderboardGet
          .items as ChallengeLeaderboardItem[],
        nextToken: data.dashboardChallengeLeaderboardGet.nextToken,
      }
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Past Challenge Leaderboard`)

      return { items: [] }
    }
  }

  // get education hours users list
  const getEducationHoursUsersList = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY totalEducationHoursUsersGet")

      const input = getInput(team!.id)

      const { data } = await dashboardTotalEducationHoursUsersGetQuery({
        variables: { input: { ...input, limit: 50 } },
      })

      logger(
        Status.Info,
        "[ENG] education hours users list",
        data.dashboardTotalEducationHoursUsersGet.items
      )

      setEducationHoursUsersList(
        data.dashboardTotalEducationHoursUsersGet.items
      )
      setEducationHoursUsersListNextToken(
        data.dashboardTotalEducationHoursUsersGet.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Education Hours Users List`)
      setEducationHoursUsersList([])
      setEducationHoursUsersListNextToken(null)

      return false
    }
  }

  // get challenge overview card
  const getDailyChallengeOverview = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY dailyChallengeOverviewGet")

      const input = getInput(team!.id)
      let nextToken = null
      let dataToSet = []

      do {
        const result: any = await dashboardDailyChallengeOverviewGetQuery({
          variables: {
            input: { ...input, limit: 1000, nextToken: nextToken },
          },
        })

        dataToSet.push(...result.data.dashboardDailyChallengeOverviewGet.items)
        nextToken = result.data.dashboardDailyChallengeOverviewGet.nextToken
      } while (nextToken)

      dataToSet = dataToSet.sort(
        (a, b) =>
          new Date(a.activityDate).getTime() -
          new Date(b.activityDate).getTime()
      )

      logger(Status.Info, "[ENG] challenge overview data", dataToSet)

      setChallengeOverviewData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Challenge Overview`)
      setChallengeOverviewData([])

      return false
    }
  }

  // get overview overtime card
  const getOverviewCardData = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY activityOverviewList")

      const input = getInput(team!.id)
      let nextToken = null
      const dataToSet = []

      do {
        const result: any = await dashboardActivityOverviewListQuery({
          variables: {
            input: { ...input, limit: 1000, nextToken: nextToken },
          },
        })

        dataToSet.push(...result.data.dashboardActivityOverviewList.items)
        nextToken = result.data.dashboardActivityOverviewList.nextToken
      } while (nextToken)

      logger(Status.Info, "[ENG] overview overtime card data", dataToSet)

      setOverviewCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Overview Card`)
      setOverviewCardData([])

      return false
    }
  }

  // get by category card
  const getByCategoryCardData = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY activitiesByCategoryList")

      const input = getInput(team!.id)

      const { data } = await dashboardActivitiesByCategoryListQuery({
        variables: { input: { ...input, limit: 100 } },
      })
      logger(
        Status.Info,
        "[ENG] by cateogory card data",
        data.dashboardActivitiesByCategoryList.items
      )

      setByCategoriesCardData(data.dashboardActivitiesByCategoryList.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} By Category Card`)
      setByCategoriesCardData([])

      return false
    }
  }

  // get team savings card
  const getTeamSavingsCardData = async () => {
    try {
      logger(Status.Api, "[ENG] QUERY savingsGet")

      const input = getInput(team!.id)

      const { data } = await dashboardSavingsGetQuery({
        variables: { input: input },
      })

      // parse data
      const dataToSet = data.dashboardSavingsGet
        ? {
            bathTubSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.bathTubSaving,
              1
            ),
            co2Saving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.co2Saving,
              1
            ),
            dishWasherSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.dishWasherSaving,
              1
            ),
            energySaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.energySaving,
              1
            ),
            phoneChargeSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.phoneChargeSaving,
              1
            ),
            waterSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.waterSaving,
              1
            ),
          }
        : null
      logger(Status.Info, "[ENG] team savings card data", dataToSet)

      setTeamSavingsCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Savings`)
      setTeamSavingsCardData(null)

      return false
    }
  }

  // load more leaderboard
  const loadMoreLeaderboardItems = async () => {
    try {
      logger(Status.Api, "QUERY challengeLeaderboardGet")

      const input = getInput(team!.id)

      const { data } = await dashboardChallengeLeaderboardGetQuery({
        variables: {
          input: { ...input, limit: 50, nextToken: leaderboardNextToken },
        },
      })

      // parse data
      let dataToSet = deepCopy(data.dashboardChallengeLeaderboardGet.items)
      dataToSet = dataToSet.filter(
        (item: any) =>
          item.user.first_name !== "deleted" &&
          item.user.last_name !== "deleted"
      )
      dataToSet.forEach((item: any) => {
        if (item.userPointsDeltaPercentage) {
          item.userPointsDeltaPercentage = roundWithDecimalPlaces(
            item.userPointsDeltaPercentage * 100,
            1
          )
        }
      })

      logger(Status.Info, "challenge leaderboard", [
        ...leaderboard,
        ...dataToSet,
      ])

      setLeaderboard([...leaderboard, ...dataToSet])
      setLeaderboardNextToken(data.dashboardChallengeLeaderboardGet.nextToken)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Leaderboard`)

      return false
    }
  }

  // load more education hours list
  const loadMoreEducationHoursUsersListItems = async () => {
    try {
      logger(Status.Api, "QUERY totalEducationHoursUsersGet")

      const input = getInput(team!.id)

      const { data } = await dashboardTotalEducationHoursUsersGetQuery({
        variables: {
          input: {
            ...input,
            limit: 50,
            nextToken: educationHoursUsersListNextToken,
          },
        },
      })

      logger(Status.Info, "education hours users list", [
        ...educationHoursUsersList,
        ...data.dashboardTotalEducationHoursUsersGet.items,
      ])

      setEducationHoursUsersList((current) => [
        ...current,
        ...data.dashboardTotalEducationHoursUsersGet.items,
      ])
      setEducationHoursUsersListNextToken(
        data.dashboardTotalEducationHoursUsersGet.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Education Hours Users List`)

      return false
    }
  }

  // get all data
  const getEngagementData = async () => {
    setEngagementDataLoading(true)

    await Promise.all([
      getDashboardCardData(),
      getChallengeCardData(),
      getPastChallengesList(),
      getEducationHoursUsersList(),
      getDailyChallengeOverview(),
      getOverviewCardData(),
      getByCategoryCardData(),
      getTeamSavingsCardData(),
    ])

    setEngagementDataLoading(false)
  }

  return (
    <EngagementContext.Provider
      value={{
        engagementDataLoading,
        dashboardCardData,
        challenge,
        pastChallengesList,
        leaderboard,
        leaderboardNextToken,
        educationHoursUsersList,
        educationHoursUsersListNextToken,
        challengeOverviewData,
        overviewCardData,
        byCategoriesCardData,
        teamSavingsCardData,
        getPastChallengeLeaderboard,
        loadMoreLeaderboardItems,
        loadMoreEducationHoursUsersListItems,
        getEngagementData,
      }}
    >
      {children}
    </EngagementContext.Provider>
  )
}
export { EngagementController, EngagementContext }
