import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { logger, Status } from "../services/utils/utils"
import SelectOption from "../models/selectOption"
import { useLazyQuery } from "@apollo/client"
import {
  categoriesList,
  dashboardTeamCountriesList,
  dashboardTeamGroupList,
  sdgsList,
} from "../services/graphql/queries"
import Group from "../models/group"
import Country from "../models/country"
import Category from "../models/category"
import Sdg from "../models/sdg"
import { AuthContext } from "./auth"
import i18next, { t } from "i18next"
import { countriesAlphaCode } from "../services/config/countries"

// context interface
interface MainContextInterface {
  selectedFilters: SelectOption[]
  setSelectedFilters: Dispatch<SetStateAction<SelectOption[]>>
  dateRange: {
    start: Date | null
    end: Date | null
  }
  setDateRange: Dispatch<
    SetStateAction<{
      start: Date | null
      end: Date | null
    }>
  >
  mainDataLoading: boolean
  setMainDataLoading: Dispatch<SetStateAction<boolean>>
  groupsList: Group[]
  countriesList: Country[]
  getMainData: () => void
  getDataFirstTime: (teamId?: string) => void
  categories: Category[]
  sdgs: Sdg[]
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: Dispatch<SetStateAction<string>>
  getInput: (teamId: string) => {
    teamId: string
    country: string[]
    groupId: string[]
    startDatetime: string | null
    endDatetime: string | null
    lang: string
  }
}

const MainContext = createContext<MainContextInterface>({
  selectedFilters: [],
  setSelectedFilters: () => {},
  dateRange: { start: new Date("2024-09-01"), end: new Date() },
  setDateRange: () => {},
  mainDataLoading: true,
  setMainDataLoading: () => {},
  groupsList: [],
  countriesList: [],
  getMainData: () => {},
  getDataFirstTime: () => {},
  categories: [],
  sdgs: [],
  error: false,
  setError: () => {},
  errorMessage: "",
  setErrorMessage: () => {},
  getInput: () => {
    return {
      teamId: "",
      country: [],
      groupId: [],
      startDatetime: null,
      endDatetime: null,
      lang: "",
    }
  },
})

const MainController = ({ children }: { children: ReactNode }) => {
  const { team, isAdmin, isSuperAdmin } = useContext(AuthContext)

  // loadings
  const [mainDataLoading, setMainDataLoading] = useState<boolean>(true)

  // states
  const [error, setError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [selectedFilters, setSelectedFilters] = useState<SelectOption[]>([])
  const [dateRange, setDateRange] = useState<{
    start: Date | null
    end: Date | null
  }>(
    isSuperAdmin
      ? { start: null, end: null }
      : isAdmin
      ? { start: new Date("2023-04-20"), end: new Date() }
      : { start: new Date("2024-09-01"), end: new Date() }
  )
  const [groupsList, setGroupsList] = useState<Group[]>([])
  const [countriesList, setCountriesList] = useState<Country[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [sdgs, setSdgs] = useState<Sdg[]>([])

  // queries
  const [categoriesListQuery] = useLazyQuery(categoriesList)
  const [sdgsListQuery] = useLazyQuery(sdgsList)
  const [dashboardTeamGroupListQuery] = useLazyQuery(dashboardTeamGroupList)
  const [dashboardTeamCountriesListQuery] = useLazyQuery(
    dashboardTeamCountriesList
  )

  // get tags lists (categories and sdgs)
  const getTagsLists = async () => {
    try {
      logger(Status.Api, "[GLOBAL] QUERY categoriesList")
      logger(Status.Api, "[GLOBAL] QUERY sdgsList")

      const input = {
        lang: i18next.language,
      }

      const result = await Promise.all([
        categoriesListQuery({
          variables: {
            input: input,
          },
        }),
        sdgsListQuery({
          variables: {
            input: input,
          },
        }),
      ])
      logger(
        Status.Info,
        "[GLOBAL] categories list",
        result[0].data.categoriesList.items
      )
      logger(Status.Info, "[GLOBAL] sdgs list", result[1].data.sdgsList.items)

      setCategories(result[0].data.categoriesList.items)
      setSdgs(result[1].data.sdgsList.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Tags`)

      return false
    }
  }

  // get input for all the queries
  const getInput = (teamId: string) => {
    return {
      teamId: teamId,
      country: selectedFilters
        .filter((filter) => filter.type === "location")
        .map((filter) => filter.id),
      groupId: selectedFilters
        .filter((filter) => filter.type === "group")
        .map((filter) => filter.id),
      startDatetime: dateRange.start
        ? dateRange.start.toISOString().split("T")[0] + "T00:00:00.000Z"
        : null,
      endDatetime: dateRange.end
        ? dateRange.end.toISOString().split("T")[0] + "T23:59:59.000Z"
        : null,
      lang: i18next.language,
    }
  }

  // get current team groups list
  const getTeamGroupsList = async (teamId: string) => {
    try {
      logger(Status.Api, "[GLOBAL] QUERY teamGroupList")
      const { data } = await dashboardTeamGroupListQuery({
        variables: { input: { teamId: teamId, limit: 100 } },
      })
      logger(
        Status.Info,
        "[GLOBAL] team groups list",
        data.dashboardTeamGroupList.items
      )

      setGroupsList(
        data.dashboardTeamGroupList.items.map((item: any) => {
          return {
            id: item.groupId,
            name: item.name,
          }
        })
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Groups`)

      return false
    }
  }

  // get current team countries list
  const getTeamCountriesList = async (teamId: string) => {
    try {
      logger(Status.Api, "[GLOBAL] QUERY teamCountriesList")

      let list: any[] = []
      let nextToken
      do {
        const result: any = await dashboardTeamCountriesListQuery({
          variables: {
            input: { teamId: teamId, limit: 100, nextToken: nextToken },
          },
        })
        list = [...list, ...result.data.dashboardTeamCountriesList.items]
        nextToken = result.data.dashboardTeamCountriesList.nextToken
      } while (nextToken)

      logger(Status.Info, "[GLOBAL] team countries list", list)

      setCountriesList(
        list
          .filter((item: any) => item.country !== "empty")
          .map((item: any) => {
            return {
              id: item.country,
              name: ((countriesAlphaCode as any)[i18next.language] as any)[
                item.country
              ],
            }
          })
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Countries`)

      return false
    }
  }

  // data fetch
  const getDataFirstTime = async (teamId?: string) => {
    setMainDataLoading(true)

    let teamIdToPass
    if (teamId) {
      teamIdToPass = teamId
    } else {
      teamIdToPass = team?.id
    }

    if (teamIdToPass) {
      await Promise.all([
        getTagsLists(),
        getTeamGroupsList(teamIdToPass),
        getTeamCountriesList(teamIdToPass),
      ])
    }

    setMainDataLoading(false)
  }

  const getMainData = async () => {
    setMainDataLoading(true)

    await Promise.all([getTagsLists(), getTeamCountriesList(team!.id)])

    setMainDataLoading(false)
  }

  useEffect(() => {
    getDataFirstTime()
  }, [])

  return (
    <MainContext.Provider
      value={{
        selectedFilters,
        setSelectedFilters,
        dateRange,
        setDateRange,
        mainDataLoading,
        setMainDataLoading,
        groupsList,
        countriesList,
        getMainData,
        getDataFirstTime,
        categories,
        sdgs,
        error,
        setError,
        errorMessage,
        setErrorMessage,
        getInput,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
