import { ButtonBase, Stack, useMediaQuery, useTheme } from "@mui/material"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import PageContainer from "../../../components/global/PageContainer"
import Card from "../../../components/global/Card"
import infoPrimaryIcon from "../../../assets/icons/info-primary.svg"
import Title from "../../../components/global/Title"
import Tabs from "../../../components/global/Tabs"
import { useContext, useEffect, useMemo, useState } from "react"
import ScatterTextChart from "../../../components/global/ScatterTextChart"
import SdgCard from "../../../components/education/SdgCard"
import Button from "../../../components/global/Button"
import ContentCard from "../../../components/education/ContentCard"
import BarChartMultiple from "../../../components/global/BarChartMultiple"
import SdgsDistributionChartXTicks from "../../../components/education/SdgsDistributionChartXTicks"
import { colors } from "../../../services/config/colors"
import BarChartLegendItem from "../../../components/global/BarChartLegendItem"
import BarChart from "../../../components/global/BarChart"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import { MainContext } from "../../../controllers/main"
import SdgCardSkeleton from "../../../components/education/SdgCardSkeleton"
import ContentCardSkeleton from "../../../components/education/ContentCardSkeleton"
import {
  capitalizeFirstCharacter,
  formatNumber,
} from "../../../services/utils/utils"
import Text from "../../../components/global/Text"
import AlertInfo from "../../../components/global/AlertInfo"
import { EducationContext } from "../../../controllers/education"

const Education = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"))
  const { categories } = useContext(MainContext)
  const {
    educationDataLoading,
    topSdgsList,
    topCategoriesList,
    topEpisodesList,
    topActionsList,
    completedEpisodesCardData,
    getEducationData,
  } = useContext(EducationContext)

  // get data
  useEffect(() => {
    getEducationData()
  }, [])

  // first section tabs
  const sdgsTabs = [t("episodes"), t("actions")]
  const [sdgsSelectedTab, setSdgsSelectedTab] = useState<number>(0)
  const categoriesTabs = [t("episodes"), t("actions")]
  const [categoriesSelectedTab, setCategoriesSelectedTab] = useState<number>(0)
  const contentTabs = [t("episodes"), t("actions")]
  const [contentSelectedTab, setContentSelectedTab] = useState<number>(0)

  // sdgs distribution tabs
  const sdgsDistributionTabs = [t("episodes"), t("actions"), t("compare")]
  const [sdgsDistributionSelectedTab, setSdgsDistributionSelectedTab] =
    useState<number>(0)

  // episodes completed tabs
  const episodesCompletedTabs = [t("by_esg"), t("by_category")]
  const [episodesCompletedSelectedTab, setEpisodesCompletedSelectedTab] =
    useState<number>(0)

  // top categories list filtered and mapped, to avoid unnecessary re-renders
  const topCategoriesListFiltered = useMemo(() => {
    return topCategoriesList
      .filter((item) =>
        categoriesSelectedTab === 0
          ? item.type === "episode"
          : item.type === "action"
      )
      .map((item) => {
        return {
          label: categories.find(
            (category) => category.id === item.activityCategory
          )!.name,
          value: item.activityAmount,
          backgroundColor: categories.find(
            (category) => category.id === item.activityCategory
          )?.backTagColor,
          color: categories.find(
            (category) => category.id === item.activityCategory
          )?.foreColor,
        }
      })
  }, [categories, topCategoriesList, categoriesSelectedTab])

  // top sdgs list sorted by sdg code
  const topSdgsListSorted = useMemo(() => {
    const topSdgsListCopy = [...topSdgsList]

    return topSdgsListCopy.sort((a, b) => {
      if (
        parseInt(a.activitySdg.slice(a.activitySdg.indexOf("#") + 1)) <
        parseInt(b.activitySdg.slice(b.activitySdg.indexOf("#") + 1))
      ) {
        return -1
      } else if (
        parseInt(a.activitySdg.slice(a.activitySdg.indexOf("#") + 1)) >
        parseInt(b.activitySdg.slice(b.activitySdg.indexOf("#") + 1))
      ) {
        return 1
      } else {
        return 0
      }
    })
  }, [topSdgsList])

  // info alerts
  const [topCategoriesAlertOpen, setTopCategoriesAlertOpen] =
    useState<boolean>(false)
  const [sdgsDistributionAlertOpen, setSdgsDistributionAlertOpen] =
    useState<boolean>(false)

  return (
    <PageContainer
      title={t("education")}
      breadcrumbs={[{ title: t("education") }]}
    >
      {/* top 3 */}
      <FadeFromTop>
        <Stack direction={{ lg: "column", xl: "row" }} gap={3}>
          {/* top 3 sdgs */}
          <Card
            width={matchesXL ? "calc(100% / 3 - 16px)" : "100%"}
            style={{ minHeight: 510 }}
          >
            <Title>{t("top_3_sdgs")}</Title>
            <Tabs
              tabs={sdgsTabs}
              selectedTab={sdgsSelectedTab}
              setSelectedTab={setSdgsSelectedTab}
            />
            {educationDataLoading ? (
              <Stack style={{ gap: 12, marginTop: 8 }}>
                <SdgCardSkeleton />
                <SdgCardSkeleton />
                <SdgCardSkeleton />
              </Stack>
            ) : sdgsSelectedTab === 0 &&
              !topSdgsList.filter((item) => item.type === "episode").length ? (
              <Text>{t("no_data")}</Text>
            ) : sdgsSelectedTab === 1 &&
              !topSdgsList.filter((item) => item.type === "action").length ? (
              <Text>{t("no_data")}</Text>
            ) : (
              <Stack style={{ gap: 12, marginTop: 8 }}>
                {topSdgsList
                  .filter((item) =>
                    sdgsSelectedTab === 0
                      ? item.type === "episode"
                      : item.type === "action"
                  )
                  .slice(0, 3)
                  .map((item) => (
                    <SdgCard
                      key={item.activitySdg}
                      code={item.activitySdg.slice(
                        item.activitySdg.indexOf("#") + 1
                      )}
                      count={item.activityAmount}
                    />
                  ))}
              </Stack>
            )}
            <Button
              primary
              size="normal"
              disabled={
                (sdgsSelectedTab === 0 &&
                  !topSdgsList.filter((item) => item.type === "episode")
                    .length) ||
                (sdgsSelectedTab === 1 &&
                  !topSdgsList.filter((item) => item.type === "action").length)
              }
              style={{
                width: "calc(100% - 48px)",
                position: "absolute",
                bottom: 24,
              }}
              onClick={() => {
                navigate(`/education/sdgs?tab=${sdgsSelectedTab}`)
              }}
              loading={educationDataLoading}
            >
              {t("see_more")}
            </Button>
          </Card>
          {/* top 3 categories */}
          <Card
            width={matchesXL ? "calc(100% / 3 - 16px)" : "100%"}
            style={{ minHeight: 510 }}
          >
            <ButtonBase
              title="Info"
              style={{
                width: 28,
                height: 28,
                position: "absolute",
                right: 24,
                borderRadius: "100%",
              }}
              onClick={() => {
                setTopCategoriesAlertOpen(true)
              }}
            >
              <img
                src={infoPrimaryIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </ButtonBase>
            <Title style={{ maxWidth: "90%" }}>{t("top_3_categories")}</Title>
            <Tabs
              tabs={categoriesTabs}
              selectedTab={categoriesSelectedTab}
              setSelectedTab={setCategoriesSelectedTab}
            />
            {topCategoriesListFiltered ? (
              <div style={{ width: "100%", height: 261 }}>
                <ScatterTextChart
                  data={topCategoriesListFiltered}
                  loading={educationDataLoading}
                />
              </div>
            ) : (
              <Text>{t("no_data")}</Text>
            )}
            <Button
              primary
              size="normal"
              disabled={!topCategoriesListFiltered.length}
              onClick={() => {
                navigate(`/education/categories?tab=${categoriesSelectedTab}`)
              }}
              loading={educationDataLoading}
            >
              {t("see_more")}
            </Button>
          </Card>
          {/* top 3 content */}
          <Card
            width={matchesXL ? "calc(100% / 3 - 16px)" : "100%"}
            style={{ minHeight: 510 }}
          >
            <Title>{t("top_3_content")}</Title>
            <Tabs
              tabs={contentTabs}
              selectedTab={contentSelectedTab}
              setSelectedTab={setContentSelectedTab}
            />
            {educationDataLoading ? (
              <Stack direction="row" style={{ gap: 12, marginTop: 8 }}>
                <ContentCardSkeleton />
                <ContentCardSkeleton />
                <ContentCardSkeleton />
              </Stack>
            ) : contentSelectedTab === 0 && !topEpisodesList.length ? (
              <Text>{t("no_data")}</Text>
            ) : contentSelectedTab === 1 && !topActionsList.length ? (
              <Text>{t("no_data")}</Text>
            ) : (
              <Stack direction="row" style={{ gap: 12, marginTop: 8 }}>
                {contentSelectedTab === 0
                  ? topEpisodesList
                      .slice(0, 3)
                      .map((item, index) => (
                        <ContentCard
                          key={index}
                          index={index}
                          title={item.episode.translation.title}
                          image={item.episode.image}
                          count={item.episodeCompleted}
                          category={item.episode.category}
                          type="episode"
                        />
                      ))
                  : topActionsList
                      .slice(0, 3)
                      .map((item, index) => (
                        <ContentCard
                          key={index}
                          index={index}
                          title={
                            item.action?.document.title ?? item.activityName
                          }
                          image={item.action?.image}
                          count={item.activityAmount}
                          category={
                            categories.find(
                              (category) =>
                                category.id === item.activityCategory
                            )!
                          }
                          type="action"
                        />
                      ))}
              </Stack>
            )}
            <Button
              primary
              size="normal"
              disabled={
                (contentSelectedTab === 0 && !topEpisodesList.length) ||
                (contentSelectedTab === 1 && !topActionsList.length)
              }
              style={{
                width: "calc(100% - 48px)",
                position: "absolute",
                bottom: 24,
              }}
              onClick={() => {
                navigate(`/education/content?tab=${contentSelectedTab}`)
              }}
              loading={educationDataLoading}
            >
              {t("see_more")}
            </Button>
          </Card>
        </Stack>
      </FadeFromTop>
      {/* sdgs distribution */}
      <FadeFromTop delay={0.05} style={{ marginTop: 24 }}>
        <Card style={{ overflowX: "scroll" }}>
          <ButtonBase
            title="Info"
            style={{
              width: 28,
              height: 28,
              position: "absolute",
              right: 24,
              borderRadius: "100%",
            }}
            onClick={() => {
              setSdgsDistributionAlertOpen(true)
            }}
          >
            <img
              src={infoPrimaryIcon}
              style={{ width: "100%", height: "100%" }}
            />
          </ButtonBase>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("sdgs_distribution")}</Title>
            <Stack
              direction="row"
              alignItems="center"
              style={{ width: "100%", height: 49, position: "relative" }}
            >
              <Tabs
                tabs={sdgsDistributionTabs}
                selectedTab={sdgsDistributionSelectedTab}
                setSelectedTab={setSdgsDistributionSelectedTab}
                style={{ width: 540 }}
              />
            </Stack>
            <div style={{ width: "100%", height: 200, position: "relative" }}>
              <BarChartMultiple
                data={
                  sdgsDistributionSelectedTab === 0 ||
                  sdgsDistributionSelectedTab === 1
                    ? [
                        topSdgsListSorted
                          .filter((item) =>
                            sdgsDistributionSelectedTab === 0
                              ? item.type === "episode"
                              : item.type === "action"
                          )
                          .slice(0, 17)
                          .map((item) => {
                            return {
                              label: `SDG ${parseInt(
                                item.activitySdg.slice(
                                  item.activitySdg.indexOf("#") + 1
                                )
                              )}`,
                              value: item.activityAmount,
                              backgroundColor: colors.disabled,
                            }
                          }),
                      ]
                    : [
                        topSdgsListSorted
                          .filter((item) => item.type === "episode")
                          .slice(0, 17)
                          .map((item) => {
                            return {
                              label: `SDG ${parseInt(
                                item.activitySdg.slice(
                                  item.activitySdg.indexOf("#") + 1
                                )
                              )}`,
                              value: item.activityAmount,
                              backgroundColor: colors.disabled,
                            }
                          }),
                        topSdgsListSorted
                          .filter((item) => item.type === "action")
                          .slice(0, 17)
                          .map((item) => {
                            return {
                              label: `SDG ${parseInt(
                                item.activitySdg.slice(
                                  item.activitySdg.indexOf("#") + 1
                                )
                              )}`,
                              value: item.activityAmount,
                              backgroundColor: colors.primary,
                            }
                          }),
                      ]
                }
                xTicksDisplay={false}
                loading={educationDataLoading}
              />
            </div>
            <SdgsDistributionChartXTicks />
            <div
              style={{
                width: "100%",
                height: 17,
                position: "relative",
              }}
            >
              {sdgsDistributionSelectedTab === 0 ? (
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  style={{ position: "absolute", right: 0 }}
                >
                  <BarChartLegendItem
                    label={t("episodes_completed")}
                    color={colors.disabled}
                  />
                </Stack>
              ) : sdgsDistributionSelectedTab === 1 ? (
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  style={{ position: "absolute", right: 0 }}
                >
                  <BarChartLegendItem
                    label={t("actions_logged")}
                    color={colors.disabled}
                  />
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  style={{ position: "absolute", right: 0 }}
                >
                  <BarChartLegendItem
                    label={t("episodes_completed")}
                    color={colors.disabled}
                  />
                  <BarChartLegendItem
                    label={t("actions_logged")}
                    color="#1B8FFD"
                  />
                </Stack>
              )}
            </div>
          </Stack>
        </Card>
      </FadeFromTop>
      {/* episodes completd */}
      <FadeFromTop delay={0.01} style={{ marginTop: 24 }}>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 700 }}>
            <Stack
              direction="row"
              style={{ width: "100%", position: "relative" }}
            >
              <Title
                style={{ maxWidth: "calc(100% - 120px)" }}
                loading={educationDataLoading}
              >
                {t("episodes_completed")}
              </Title>
              <Title style={{ position: "absolute", right: 0 }}>
                {t("total")}:{" "}
                <span style={{ color: colors.primary }}>
                  {completedEpisodesCardData[0]
                    ? formatNumber(completedEpisodesCardData[0].totalAmount)
                    : 0}
                </span>
              </Title>
            </Stack>
            <Tabs
              tabs={episodesCompletedTabs}
              selectedTab={episodesCompletedSelectedTab}
              setSelectedTab={setEpisodesCompletedSelectedTab}
              style={{ width: 362, maxWidth: "100%" }}
            />
            <div style={{ width: "100%", height: 280, position: "relative" }}>
              <BarChart
                data={completedEpisodesCardData
                  .filter((item) =>
                    episodesCompletedSelectedTab === 0
                      ? item.partitionType === "esg"
                      : item.partitionType === "category"
                  )
                  .filter(
                    (item) =>
                      item.activityPartitionType &&
                      item.activityPartitionType !== "empty"
                  )
                  .map((item) => {
                    return {
                      label:
                        episodesCompletedSelectedTab === 0
                          ? capitalizeFirstCharacter(item.activityPartitionType)
                          : categories.find(
                              (category) =>
                                category.id === item.activityPartitionType
                            )!.name,
                      value: item.partitionAmount,
                      backgroundColor:
                        episodesCompletedSelectedTab === 0
                          ? item.activityPartitionType === "social"
                            ? colors.esgs.society
                            : item.activityPartitionType === "environment"
                            ? colors.esgs.environment
                            : colors.esgs.governance
                          : categories.find(
                              (category) =>
                                category.id === item.activityPartitionType
                            )!.backTagColor,
                      color:
                        episodesCompletedSelectedTab === 0
                          ? item.activityPartitionType === "social"
                            ? colors.esgs.societyText
                            : item.activityPartitionType === "environment"
                            ? colors.esgs.environmentText
                            : colors.esgs.governanceText
                          : categories.find(
                              (category) =>
                                category.id === item.activityPartitionType
                            )!.foreColor,
                    }
                  })}
                maxBarThickness={50}
                loading={educationDataLoading}
              />
            </div>
          </Stack>
        </Card>
      </FadeFromTop>
      {/* alerts */}
      <AlertInfo
        open={topCategoriesAlertOpen}
        setOpen={setTopCategoriesAlertOpen}
        title={t("top_3_categories")}
        description={t("top_3_categories_description")}
      />
      <AlertInfo
        open={sdgsDistributionAlertOpen}
        setOpen={setSdgsDistributionAlertOpen}
        title={t("sdgs_distribution")}
        description={t("sdgs_distribution_description")}
      />
    </PageContainer>
  )
}

export default Education
