import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "./Title"
import closeWhiteIcon from "../../assets/icons/close-white.svg"
import { useContext } from "react"
import { t } from "i18next"
import { defaultTransition } from "../../services/config/constants"
import { HelperContext } from "../../controllers/helper"

const FilterTag = ({
  name,
  onRemove,
}: {
  name: string
  onRemove: () => void
}) => {
  const { loading } = useContext(HelperContext)

  return (
    <Stack
      direction="row"
      gap={0.5}
      style={{
        padding: 8,
        backgroundColor: colors.primary,
        borderRadius: 4,
      }}
    >
      <Title
        fontSize={12}
        fontWeight={900}
        lineHeight="14px"
        color={colors.textWhite}
        ellipsis
        style={{ maxWidth: 220 }}
      >
        {name}
      </Title>
      <ButtonBase
        aria-label={t("remove")}
        style={{ borderRadius: "100%" }}
        onClick={onRemove}
        disabled={loading}
      >
        <img
          src={closeWhiteIcon}
          style={{
            width: 16,
            height: 16,
            transition: defaultTransition,
            opacity: loading ? 0.4 : 1,
          }}
        />
      </ButtonBase>
    </Stack>
  )
}

export default FilterTag
