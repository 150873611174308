import { createContext, ReactNode, useContext, useState } from "react"
import { MainContext } from "./main"
import { AuthContext } from "./auth"
import { t } from "i18next"
import {
  logger,
  Status,
  deepCopy,
  roundWithDecimalPlaces,
} from "../services/utils/utils"
import FootprintOverviewDataItem from "../models/footprintOverviewDataItem"
import FootprintOverviewCardsData from "../models/footprintOverviewCardsData"
import FootprintBreakdownCardDataItem from "../models/footprintBreakdownCardDataItem"
import FootprintBreakdownByCategoriesDataItem from "../models/footprintBreakdownByCategoriesDataItem"
import FootprintByGroupCardDataItem from "../models/footprintByGroupCardDataItem"
import { useLazyQuery } from "@apollo/client"
import {
  dashboardFootprintMonthlyUserGet,
  dashboardFootprintOverviewGet,
  dashboardFootprintBreakdownGet,
  dashboardFootprintBreakdownByGroupGet,
  dashboardFootprintCategoryBreakdownGet,
} from "../services/graphql/queries"
import { parseFootprintByCategoryData } from "../services/utils/parseFunctions"

interface FootprintContextInterface {
  footprintDataLoading: boolean
  footprintOverviewCardData: FootprintOverviewDataItem[]
  footprintOverviewCardsData: FootprintOverviewCardsData | null
  footprintBreakdownCardData: FootprintBreakdownCardDataItem[]
  footprintBreakdownByCategoriesData: FootprintBreakdownByCategoriesDataItem[]
  footprintByGroupCardData: FootprintByGroupCardDataItem[]
  getFootprintData: () => void
}

const FootprintContext = createContext<FootprintContextInterface>({
  footprintDataLoading: true,
  footprintOverviewCardData: [],
  footprintOverviewCardsData: null,
  footprintBreakdownCardData: [],
  footprintBreakdownByCategoriesData: [],
  footprintByGroupCardData: [],
  getFootprintData: () => {},
})

const FootprintController = ({ children }: { children: ReactNode }) => {
  const { team } = useContext(AuthContext)
  const { getInput, setError, setErrorMessage } = useContext(MainContext)

  // states
  const [footprintDataLoading, setFootprintDataLoading] =
    useState<boolean>(false)
  const [footprintOverviewCardData, setFootprintOverviewCardData] = useState<
    FootprintOverviewDataItem[]
  >([])
  const [footprintOverviewCardsData, setFootprintOverviewCardsData] =
    useState<FootprintOverviewCardsData | null>(null)
  const [footprintBreakdownCardData, setFootprintBreakdownCardData] = useState<
    FootprintBreakdownCardDataItem[]
  >([])
  const [
    footprintBreakdownByCategoriesData,
    setFootprintBreakdownByCategoriesData,
  ] = useState<FootprintBreakdownByCategoriesDataItem[]>([])
  const [footprintByGroupCardData, setFootprintByGroupCardData] = useState<
    FootprintByGroupCardDataItem[]
  >([])

  // queries
  const [dashboardFootprintMonthlyUserGetQuery] = useLazyQuery(
    dashboardFootprintMonthlyUserGet
  )
  const [dashboardFootprintOverviewGetQuery] = useLazyQuery(
    dashboardFootprintOverviewGet
  )
  const [dashboardFootprintBreakdownGetQuery] = useLazyQuery(
    dashboardFootprintBreakdownGet
  )
  const [dashboardFootprintBreakdownByGroupGetQuery] = useLazyQuery(
    dashboardFootprintBreakdownByGroupGet
  )
  const [dashboardFootprintCategoryBreakdownGetQuery] = useLazyQuery(
    dashboardFootprintCategoryBreakdownGet
  )

  // get footprint overview
  const getFootprintOverviewCardData = async () => {
    try {
      logger(Status.Api, "[CFP] QUERY dashboardFootprintMonthlyUserGet")

      const input = getInput(team!.id)

      const { data } = await dashboardFootprintMonthlyUserGetQuery({
        variables: {
          input: {
            ...input,
            startDatetime: null,
            endDatetime: null,
            limit: 1000,
          },
        },
      })
      logger(
        Status.Info,
        "[CFP] footprint overview overtime card data",
        data.dashboardFootprintMonthlyUserGet.items
      )

      setFootprintOverviewCardData(data.dashboardFootprintMonthlyUserGet.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint Breakdown`)
      setFootprintBreakdownCardData([])

      return false
    }
  }

  // get footprint overview overtime cards
  const getFootprintOverviewCardsData = async () => {
    try {
      logger(Status.Api, "[CFP] QUERY footprintOverviewGet")

      const input = getInput(team!.id)

      const { data } = await dashboardFootprintOverviewGetQuery({
        variables: {
          input: { ...input, startDatetime: null, endDatetime: null },
        },
      })

      // parse data
      const dataToSet = data.dashboardFootprintOverviewGet
        ? {
            ...data.dashboardFootprintOverviewGet,
            avgEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.avgEmissionAmount / 1000,
              1
            ),
            maxEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.maxEmissionAmount / 1000,
              1
            ),
            minEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.minEmissionAmount / 1000,
              1
            ),
            parisAgreementEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.parisAgreementEmissionAmount /
                1000,
              1
            ),
            parisAgreementEmissionDeltaPercentage: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet
                .parisAgreementEmissionDeltaPercentage * 100,
              1
            ),
            worldEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.worldEmissionAmount / 1000,
              1
            ),
            worldEmissionDeltaPercentage: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.worldEmissionDeltaPercentage *
                100,
              1
            ),
          }
        : null
      logger(Status.Info, "[CFP] footprint overview cards data", dataToSet)

      setFootprintOverviewCardsData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint`)
      setFootprintOverviewCardsData(null)

      return false
    }
  }

  // get footprint breakdown card
  const getFootprintBreakdownCardData = async () => {
    try {
      logger(Status.Api, "[CFP] QUERY footprintBreakdownGet")

      const input = getInput(team!.id)

      const { data } = await dashboardFootprintBreakdownGetQuery({
        variables: {
          input: { ...input, startDatetime: null, endDatetime: null },
        },
      })

      // parse data
      const dataToSet = data.dashboardFootprintBreakdownGet
        ? deepCopy(data.dashboardFootprintBreakdownGet.items)
        : null
      if (dataToSet) {
        dataToSet.forEach((item: FootprintBreakdownCardDataItem) => {
          item.categoryEmissionAmount = roundWithDecimalPlaces(
            item.categoryEmissionAmount / 1000,
            1
          )
          item.categoryEmissionPercentage = roundWithDecimalPlaces(
            item.categoryEmissionPercentage * 100,
            1
          )
          item.totalEmissionAmount = roundWithDecimalPlaces(
            item.totalEmissionAmount / 1000,
            1
          )
        })
      }
      logger(Status.Info, "[CFP] footprint breakdown card data", dataToSet)

      setFootprintBreakdownCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint Breakdown`)
      setFootprintBreakdownCardData([])

      return false
    }
  }

  // get footprint breakdown card by category
  const getFootprintByCategoryData = async () => {
    try {
      logger(Status.Api, "[CFP] QUERY footprintCategoryBreakdownGet")

      const input = getInput(team!.id)

      const { data } = await dashboardFootprintCategoryBreakdownGetQuery({
        variables: {
          input: {
            ...input,
            startDatetime: null,
            endDatetime: null,
            limit: 100,
          },
        },
      })

      // parse data
      let dataToSet = []
      if (
        data.dashboardFootprintCategoryBreakdownGet &&
        data.dashboardFootprintCategoryBreakdownGet.items
      ) {
        dataToSet = parseFootprintByCategoryData(
          data.dashboardFootprintCategoryBreakdownGet.items
        )
      }

      logger(
        Status.Info,
        "[CFP] footprint breakdown by category data",
        dataToSet
      )

      setFootprintBreakdownByCategoriesData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint Breakdown Detailed`)
      setFootprintBreakdownByCategoriesData([])

      return false
    }
  }

  // get footprint breakdown by group card
  const getFootprintByGroupCardData = async () => {
    try {
      logger(Status.Api, "[CFP] QUERY footprintBreakdownByGroupGet")

      let nextToken = null
      const dataToSet: FootprintByGroupCardDataItem[] = []

      do {
        const input = getInput(team!.id)

        const result: any = await dashboardFootprintBreakdownByGroupGetQuery({
          variables: {
            input: {
              ...input,
              startDatetime: null,
              endDatetime: null,
              limit: 100,
              nextToken,
            },
          },
        })

        nextToken = result.data.dashboardFootprintBreakdownByGroupGet.nextToken

        // parse data
        const dataToPush: FootprintByGroupCardDataItem[] = []
        result.data.dashboardFootprintBreakdownByGroupGet.items.forEach(
          (item: any) => {
            dataToPush.push({
              category: item.category,
              categoryEmissionAmount: roundWithDecimalPlaces(
                item.categoryEmissionAmount / 1000,
                1
              ),
              categoryEmissionPercentage: roundWithDecimalPlaces(
                item.categoryEmissionPercentage * 100,
                1
              ),
              groupId: item.groupId,
            })
          }
        )

        dataToSet.push(...dataToPush)
      } while (nextToken)

      logger(
        Status.Info,
        "[CFP] footprint breakdown by group card data",
        dataToSet
      )

      setFootprintByGroupCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint By Group`)
      setFootprintByGroupCardData([])

      return false
    }
  }

  // get all data
  const getFootprintData = async () => {
    setFootprintDataLoading(true)

    await Promise.all([
      getFootprintOverviewCardData(),
      getFootprintOverviewCardsData(),
      getFootprintBreakdownCardData(),
      getFootprintByCategoryData(),
      getFootprintByGroupCardData(),
    ])

    setFootprintDataLoading(false)
  }

  return (
    <FootprintContext.Provider
      value={{
        footprintDataLoading,
        footprintOverviewCardData,
        footprintOverviewCardsData,
        footprintBreakdownCardData,
        footprintBreakdownByCategoriesData,
        footprintByGroupCardData,
        getFootprintData,
      }}
    >
      {children}
    </FootprintContext.Provider>
  )
}
export { FootprintController, FootprintContext }
