import { t } from "i18next"
import { colors } from "../../services/config/colors"
import Text from "../global/Text"
import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import { ButtonBase, Stack } from "@mui/material"
import months from "../../services/config/months"
import chevronLeftIcon from "../../assets/icons/chevron-left-small.svg"
import chevronRightIcon from "../../assets/icons/chevron-right-small.svg"
import { defaultTransition } from "../../services/config/constants"
import {
  getLastDayOfMonth,
  getMonday,
  getSunday,
} from "../../services/utils/utils"
import { EngagementContext } from "../../controllers/engagement"

const ChallengeOverviewIntervalSelect = ({
  selectedOption,
  interval,
  setInterval,
  loading,
}: {
  selectedOption: number
  interval: {
    start: Date
    end: Date
  }
  setInterval: Dispatch<
    SetStateAction<{
      start: Date
      end: Date
    }>
  >
  loading: boolean
}) => {
  const { dateRange } = useContext(MainContext)
  const { challengeOverviewData } = useContext(EngagementContext)

  // adjust interval based on global date range
  useEffect(() => {
    if (selectedOption === 0) {
      const startDateToSet = dateRange.end
        ? getMonday(dateRange.end)
        : getMonday(new Date())
      const endDateToSet = dateRange.end
        ? getSunday(dateRange.end)
        : getSunday(new Date())

      setInterval({
        start: new Date(
          startDateToSet.getFullYear(),
          startDateToSet.getMonth(),
          startDateToSet.getDate(),
          new Date().getHours()
        ),
        end: new Date(
          endDateToSet.getFullYear(),
          endDateToSet.getMonth(),
          endDateToSet.getDate(),
          new Date().getHours()
        ),
      })
    } else if (selectedOption === 1) {
      const startDateToSet = dateRange.end
        ? new Date(new Date(dateRange.end).setMonth(dateRange.end.getMonth()))
        : new Date(new Date().setMonth(new Date().getMonth()))

      setInterval({
        start: new Date(
          startDateToSet.getFullYear(),
          startDateToSet.getMonth(),
          1,
          new Date().getHours()
        ),
        end: new Date(
          dateRange.end
            ? dateRange.end.getFullYear()
            : new Date().getFullYear(),
          dateRange.end ? dateRange.end.getMonth() : new Date().getMonth(),
          getLastDayOfMonth(
            dateRange.end
              ? dateRange.end.getFullYear()
              : new Date().getFullYear(),
            dateRange.end ? dateRange.end.getMonth() : new Date().getMonth()
          ),
          new Date().getHours()
        ),
      })
    } else if (selectedOption === 2) {
      setInterval({
        start: new Date(
          dateRange.start
            ? dateRange.start.getFullYear()
            : new Date().getFullYear(),
          0,
          1,
          new Date().getHours()
        ),
        end: new Date(
          dateRange.start
            ? dateRange.start.getFullYear()
            : new Date().getFullYear(),
          11,
          31,
          new Date().getHours()
        ),
      })
    } else if (selectedOption === 3) {
      setInterval({
        start: new Date(
          new Date(challengeOverviewData[0].activityDate).getFullYear(),
          0,
          1,
          12
        ),
        end: new Date(
          new Date(
            challengeOverviewData[challengeOverviewData.length - 1].activityDate
          ).getFullYear(),
          11,
          31,
          12
        ),
      })
    }
  }, [selectedOption, dateRange])

  return selectedOption === 3 ? null : (
    <Stack direction="row" alignItems="center" gap={1} style={{ height: 20 }}>
      <ButtonBase
        disabled={
          loading ||
          (selectedOption === 2 &&
            dateRange.start &&
            dateRange.start.getFullYear() >= interval.start.getFullYear()) ||
          ((selectedOption === 0 || selectedOption === 1) &&
            dateRange.start &&
            dateRange.start >= interval.start)
            ? true
            : false
        }
        style={{
          width: 20,
          minWidth: 20,
          height: 20,
          borderRadius: "100%",
        }}
        onClick={() => {
          if (selectedOption === 0) {
            interval.start = new Date(
              new Date(interval.start).setDate(interval.start.getDate() - 7)
            )
            interval.end = new Date(
              new Date(interval.end).setDate(interval.end.getDate() - 7)
            )
          } else if (selectedOption === 1) {
            interval.start = new Date(
              new Date(interval.start).setMonth(interval.start.getMonth() - 1)
            )
            interval.end = new Date(
              interval.end.getFullYear(),
              interval.end.getMonth() - 1,
              getLastDayOfMonth(
                interval.end.getFullYear(),
                interval.end.getMonth() - 1
              ),
              interval.end.getHours()
            )
          } else if (selectedOption === 2) {
            interval.start = new Date(
              interval.start.getFullYear() - 1,
              0,
              1,
              new Date().getHours()
            )
            interval.end = new Date(
              interval.end.getFullYear() - 1,
              11,
              31,
              new Date().getHours()
            )
          }

          setInterval({ ...interval })
        }}
      >
        <img
          src={chevronLeftIcon}
          alt=""
          style={{
            transition: defaultTransition,
            opacity:
              loading ||
              (selectedOption === 2 &&
                dateRange.start &&
                dateRange.start.getFullYear() >=
                  interval.start.getFullYear()) ||
              ((selectedOption === 0 || selectedOption === 1) &&
                dateRange.start &&
                dateRange.start >= interval.start)
                ? 0.4
                : 1,
          }}
        />
      </ButtonBase>
      {selectedOption === 0 ? (
        <Text
          fontSize={12}
          lineHeight="18px"
          fontWeight={500}
          color={colors.primary}
        >
          {t("week")}:{" "}
          {`${interval.start.getDate()} ${t(
            months[interval.start.getMonth()]
          ).slice(0, 3)}`}{" "}
          -{" "}
          {`${interval.end.getDate()} ${t(
            months[interval.end.getMonth()]
          ).slice(0, 3)} ${interval.end.getFullYear()}`}
        </Text>
      ) : selectedOption === 1 ? (
        <Text
          fontSize={12}
          lineHeight="18px"
          fontWeight={500}
          color={colors.primary}
        >
          {t("month")}: {t(months[interval.end.getMonth()])}{" "}
          {interval.end.getFullYear()}
        </Text>
      ) : selectedOption === 2 ? (
        <Text
          fontSize={12}
          lineHeight="18px"
          fontWeight={500}
          color={colors.primary}
        >
          {t("year")}: {interval.start.getFullYear()}
        </Text>
      ) : null}
      <ButtonBase
        disabled={
          loading ||
          (selectedOption === 2 &&
            dateRange.end &&
            dateRange.end.getFullYear() <= interval.end.getFullYear()) ||
          (selectedOption === 2 &&
            !dateRange.end &&
            new Date().getFullYear() <= interval.end.getFullYear()) ||
          ((selectedOption === 0 || selectedOption === 1) &&
            dateRange.end &&
            dateRange.end <= interval.end) ||
          ((selectedOption === 0 || selectedOption === 1) &&
            !dateRange.end &&
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ) <=
              new Date(
                interval.end.getFullYear(),
                interval.end.getMonth(),
                interval.end.getDate()
              ))
            ? true
            : false
        }
        style={{
          width: 20,
          minWidth: 20,
          height: 20,
          borderRadius: "100%",
        }}
        onClick={() => {
          if (selectedOption === 0) {
            interval.start = new Date(
              new Date(interval.start).setDate(interval.start.getDate() + 7)
            )
            interval.end = new Date(
              new Date(interval.end).setDate(interval.end.getDate() + 7)
            )
          } else if (selectedOption === 1) {
            interval.start = new Date(
              new Date(interval.start).setMonth(interval.start.getMonth() + 1)
            )
            interval.end = new Date(
              interval.end.getFullYear(),
              interval.end.getMonth() + 1,
              getLastDayOfMonth(
                interval.end.getFullYear(),
                interval.end.getMonth() + 1
              ),
              interval.end.getHours()
            )
          } else if (selectedOption === 2) {
            interval.start = new Date(
              interval.start.getFullYear() + 1,
              0,
              1,
              new Date().getHours()
            )
            interval.end = new Date(
              interval.end.getFullYear() + 1,
              11,
              31,
              new Date().getHours()
            )
          }

          setInterval({ ...interval })
        }}
      >
        <img
          src={chevronRightIcon}
          alt=""
          style={{
            transition: defaultTransition,
            opacity:
              loading ||
              (selectedOption === 2 &&
                dateRange.end &&
                dateRange.end.getFullYear() <= interval.end.getFullYear()) ||
              (selectedOption === 2 &&
                !dateRange.end &&
                new Date().getFullYear() <= interval.end.getFullYear()) ||
              ((selectedOption === 0 || selectedOption === 1) &&
                dateRange.end &&
                dateRange.end <= interval.end) ||
              ((selectedOption === 0 || selectedOption === 1) &&
                !dateRange.end &&
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate()
                ) <=
                  new Date(
                    interval.end.getFullYear(),
                    interval.end.getMonth(),
                    interval.end.getDate()
                  ))
                ? 0.4
                : 1,
          }}
        />
      </ButtonBase>
    </Stack>
  )
}

export default ChallengeOverviewIntervalSelect
