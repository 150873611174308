import {
  Stack,
  useMediaQuery,
  useTheme,
  Alert as MuiAlert,
  Snackbar,
} from "@mui/material"
import Menu from "../../components/navigation/Menu"
import { Route, Routes, useLocation } from "react-router-dom"
import {
  contentRoutes,
  accountRoutes,
  otherRoutes,
} from "../../services/config/routes"
import { MainContext } from "../../controllers/main"
import Alert from "../../components/global/Alert"
import { useContext } from "react"
import { AuthContext } from "../../controllers/auth"
import { t } from "i18next"
import ModalMenu from "../../components/navigation/ModalMenu"

const Main = () => {
  const theme = useTheme()
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"))
  const location = useLocation()
  const {
    logOutAlertOpen,
    setLogOutAlertOpen,
    logOut,
    modalMenuOpen,
    setModalMenuOpen,
    team,
  } = useContext(AuthContext)
  const { error, setError, errorMessage } = useContext(MainContext)

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
      }}
    >
      {/* menu */}
      {matchesLG ? (
        <Menu />
      ) : (
        <ModalMenu open={modalMenuOpen} setOpen={setModalMenuOpen} />
      )}
      {/* content */}
      <Routes location={location}>
        {contentRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
        {accountRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
        {otherRoutes
          .filter((route) => {
            if (team?.isPrimary) {
              return route
            } else if (route.path !== "/engagement/education-hours") {
              return route
            }
          })
          .map((route, index) => (
            <Route key={index} path={route.path} element={route.component} />
          ))}
      </Routes>
      {/* logout alert */}
      <Alert
        open={logOutAlertOpen}
        title={t("logout_alert_title")}
        description={t("logout_alert_description")}
        primaryActionLabel={t("logout")}
        primaryActionOnClick={logOut}
        secondaryActionLabel={t("go_back")}
        secondaryActionOnClick={() => {
          setLogOutAlertOpen(false)
        }}
      />
      {/* error alert */}
      <Snackbar
        open={error}
        autoHideDuration={4000}
        onClose={() => {
          setError(false)
        }}
      >
        <MuiAlert
          onClose={() => {
            setError(false)
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Stack>
  )
}

export default Main
